import { AbilityContext, Figure, Heading, Icon } from '@/components';
import { Alert, Grid } from '@mui/material';
import { DashboardBlock } from './Home.styled';
import { useQuery } from '@tanstack/react-query';
import { getMyTasks } from '@/api';
import { MY_TASKS_FIGURE_TYPES, ROUTE } from '@/constants';
import { useContext, useMemo } from 'react';
import { getAPIErrorMessage, getNavigateObject } from '@/utils';
import { formatInTimeZone } from 'date-fns-tz';
import { set } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { ClientStatus } from '@/types';

export function SectionMyTasks() {
  const navigate = useNavigate();
  const ability = useContext(AbilityContext);

  const { data, error } = useQuery({
    queryKey: ['my-tasks'],
    queryFn: getMyTasks,
    retry: false,
  });

  const figures = useMemo(() => {
    return MY_TASKS_FIGURE_TYPES.map(config => {
      let onClick: (() => void) | undefined;

      const now = new Date();
      const startToday = set(now, { hours: 0, minutes: 0, seconds: 0 });
      const endToday = set(now, { hours: 23, minutes: 59, seconds: 59 });

      switch (config.field) {
        case 'pendingApproval':
          onClick = () =>
            navigate(
              getNavigateObject(ROUTE.CLIENTS.index, {
                queries: {
                  applicationStatuses: ClientStatus.PendingApproval,
                  selfAssigned: 'true',
                },
              }),
            );
          break;
        case 'total':
          onClick = () =>
            navigate(
              getNavigateObject(ROUTE.CLIENTS.index, {
                queries: {
                  selfAssigned: 'true',
                },
              }),
            );
          break;
        case 'dueToday':
          onClick = () =>
            navigate(
              getNavigateObject(ROUTE.CLIENTS.index, {
                queries: {
                  selfAssigned: 'true',
                  dueFrom: formatInTimeZone(
                    startToday,
                    'UTC',
                    "yyyy-MM-dd'T'HH:mm:ssX",
                  ),
                  dueTo: formatInTimeZone(
                    endToday,
                    'UTC',
                    "yyyy-MM-dd'T'HH:mm:ssX",
                  ),
                },
              }),
            );
          break;
        case 'pastDue':
          onClick = () =>
            navigate(
              getNavigateObject(ROUTE.CLIENTS.index, {
                queries: {
                  selfAssigned: 'true',
                  dueTo: formatInTimeZone(now, 'UTC', "yyyy-MM-dd'T'HH:mm:ssX"),
                },
              }),
            );
          break;
      }

      return {
        ...config,
        value: data?.[config.field] || 0,
        onClick,
      };
    });
  }, [data, navigate]);

  return (
    <DashboardBlock>
      <Heading title="My tasks today" isSubSection />
      {error && (
        <Alert icon={<Icon name="alert" />} color="error" variant="standard">
          {getAPIErrorMessage(error)}
        </Alert>
      )}
      <Grid container spacing={1.5}>
        {figures.map(({ value, text, color, showWarning, onClick }) => (
          <Grid key={text} item xs={4}>
            <Figure
              value={value}
              title={text}
              color={color}
              height={104}
              py={2}
              px={1}
              withWarningIcon={showWarning}
              {...(ability.can('view', 'client') && { onClick })}
            />
          </Grid>
        ))}
      </Grid>
    </DashboardBlock>
  );
}
