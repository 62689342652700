import { Document, DocumentStatus, RequiredDocumentSection } from './document';

export enum ClientTitle {
  MR = 43,
  MS = 46,
  MRS = 44,
}

export enum ClientStatus {
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  PendingApproval = 'PENDING_APPROVAL',
  Archived = 'ARCHIVED',
}

export enum Gender {
  Male = 'M',
  Female = 'F',
}

export enum PEPStatus {
  Exposed = 'pep.check.found',
  InProgress = 'pep.check.in_progress',
  Verified = 'pep.check.verified',
  Approved = 'pep.check.approved',
}

export enum CautionCheckStatus {
  Matched = 'matched',
  UnMatched = 'unmatched',
}

export enum AMLStatus {
  Sanctioned = 'aml.check.found',
  InProgress = 'aml.check.in_progress',
  Verified = 'aml.check.verified',
  Approved = 'aml.check.approved',
}

export enum EmploymentStatus {
  Employed = 'EMPLOYED',
  SelfEmployed = 'SELF_EMPLOYED',
  Unemployed = 'UNEMPLOYED',
}

export enum Profession {
  SoleTrader = 'Sole_Trader',
  PartnershipLimitedLiability = 'Partnership_Limited_Liability',
  Unincorporated = 'Unincorporated',
  Student = 'Student',
  Pensioner = 'Pensioner',
  Housewife = 'Housewife',
  Unemployed = 'Unemployed',
}

export enum FundingSource {
  Salary = 'SALARY',
  Savings = 'SAVINGS',
  FundingFromSpouse = 'FUNDING_FROM_SPOUSE',
  FundingFromParents = 'FUNDING_FROM_PARENTS',
  BusinessProceeds = 'BUSINESS_PROCEEDS',
  Investments = 'INVESTMENTS',
  Other = 'OTHER',
}

export type AddressDetails = {
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  town?: string;
  district?: string;
  zip?: string;
};

export type EmployeeDetailed = {
  createAt?: string;
  employerAddress?: string;
  employerName?: string;
  employerPhone?: string;
  occupationCode?: string;
  startDate?: string;
};

export enum ICBResponseCode {
  Success = 0,
  CreateRIMError = -1,
  CreateICBError = -2,
  UsernameRequired = 1005,
  UsernameError = 5,
  GenericError = 2,
  IdentificationRequired = 1001,
  NameAndSurnameRequired = 1002,
  MobilePhoneRequired = 1003,
  ClientInformationRequired = 1004,
  BirthDateRequired = 1006,
}

export enum PepAnswerStatus {
  InReview = 'IN_REVIEW',
  Cancelled = 'CANCELLED',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
}

export type Client = {
  id: string | number;
  email: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  picture?: string;
  rim?: string;
  title?: string;
  icbResponseCode?: ICBResponseCode;
  birthday?: string;
  citizenship?: string;
  address?: string;
  addressDetailed?: AddressDetails;
  employmentDetailed?: EmployeeDetailed;
  documents?: Document[];
  country?: string;
  assignee?: string;
  reviewBy?: string;
  operationId?: string | number;
  username?: string;

  citizenship2?: string;
  termsAndConditions?: boolean;
  usIndicia?: boolean;
  citizenship2Has?: boolean;

  riskLevel?: string;
  personaType?: string;
  gender?: Gender;
  phoneNumber: string;
  taxResidence?: string;
  status: ClientStatus;
  documentStatuses?: {
    value: DocumentStatus;
    count: number;
  }[];
  employmentStatus?: EmploymentStatus;
  employmentDesc?: Profession;
  progress?: number;

  pepStatus?: PepCheckResult;
  pepAnswer?: {
    answer: {
      answers: PepAnswer[];
    };
    created_at: string;
    flagged: boolean;
    status: PepAnswerStatus;
    updated_at: string;
    id: string | number;
  };
  pepAnswerEntries?: PepAnswerEntries[];
  branchCode: number;
  branchName: string;
  branchId: string;
  uri?: string;
  eligible?: boolean;
  createdAt: string;
  infoAccuracyConfirmed?: boolean;
  monthlyIncome?: number;
  monthlyIncomeCurrency?: string;
  fundingSource?: string;
  tin: {
    [key: string]: string;
  };
  selfEmployedRiskSectorFlag?: boolean;
  cautionCheck?: boolean;
  requiredDocumentSections: RequiredDocumentSection[];
};

export type PepCheckResult = {
  pepCheckStatus?: PEPStatus;
  amlCheckStatus?: AMLStatus;
  cautionCheckStatus?: CautionCheckStatus;
};

export type PepAnswer = {
  positive: boolean;
  isNo: boolean;
  question: string;
  questionId: string;
  fullName?: string;
  relationship?: string;
  position?: string;
  countryCode?: string;
  meWorking?: boolean;
  isPersonWorking?: boolean;
  startDate?: string;
  endDate?: string;
};

export type PepAnswerEntries = {
  code: string;
  countryCode?: string;
  meWorking?: boolean;
  position?: string;
  positive: boolean;
  question: string;
  questionId: string;
  startDate?: string;
};

export enum QuestionCode {
  Connection = 'pep.question.connection',
  Relatives = 'pep.question.relatives',
  Personal = 'pep.question.ownuse',
  USIndica = 'us-indicia',
  DualCitizenship = 'dual-citizenship',
  ExpectedMonthlyDeposit = 'expected-monthly-deposit',
  FundingSource = 'funding-source',
}

export enum VUResultCode {
  Ok = 903,
  Fail = 904,
  EmptyFail = 905,
  FrontBackNotBelong = 1907,
  BarcodeNotExists = 1910,
  DocumentExpirated = 1911,
  DocumentNotExpirated = 1912,
  DocumentExpiratedNotImplemented = 1913,
  MandatoryIdFieldMissing = 1914,
  MandatoryIdFieldOk = 1915,
  ResponseFail = 1920,
  FaceDetectionDocumentFaceNotFound = 1921,
  FaceDetectionValidationFail = 1922,
  MlFaceAntispoofingFail = 1930,
  BiometricsEmptyFail = 1931,
}

export type PEPHit = {
  name: string;
} & Record<string, number | string | string[]>;

export type PEPRecord = {
  id: string;
  clientId: string;
  aml_status: boolean;
  pep_status: boolean;
  response: {
    timestamp: string;
    total_hits: number;
    found_records: PEPHit[];
  };
};

export enum AccountTypeStatus {
  Active = 'ACTIVE',
  ComingSoon = 'COMING',
}

export type AccountType = {
  accountNo: string;
  classCode: string;
  currency: string;
  id: string;
  name: string;
  status: AccountTypeStatus;
  type: string;
};

export enum NoteType {
  BasicInfo = 'BASIC_INFO',
  ProofOfEmployment = 'PROOF_OF_EMPLOYMENT',
  DocumentsSigning = 'DOCUMENTS_SIGNING',
  AccountTopup = 'ACCOUNT_TOPUP',
  Others = 'OTHERS',
}

export type Note = {
  id: string;
  clientId: number;
  creatorFirstName: string;
  creatorLastName: string;
  creatorEmail: string;
  creatorAvatar?: string;
  operationId: string;
  content: string;
  category: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
};

export type AccountTypeDetails = {
  classCode: string;
  currency?: string;
  id: string;
  name: string;
  status: string;
  type: string;
};

export type SimilarAccount = {
  lastName: string;
  firstName: string;
  rimNo: string;
  birthday: string;
};

export type Occupation = {
  code: string;
  value: string;
};
