import { Client, Document, ModalName, ToastState, User } from '@/types';
import { createAction } from '@reduxjs/toolkit';

export const showDocumentRequestDrawer = createAction<Client | undefined>(
  'ui/show-document-request-drawer',
);
export const showManageDocumentsDrawer = createAction<Client | undefined>(
  'ui/show-manage-document-drawer',
);

export const showDocumentReviewDrawer = createAction<{
  client?: Client;
  document: Document;
}>('ui/show-document-review-drawer');

export const showAddUserDrawer = createAction<User | undefined>(
  'ui/add-user-drawer',
);

export const showUpdateUserDrawer = createAction<{
  user: User;
}>('ui/update-user-drawer');

export const showChangeUserRoleDrawer = createAction<{
  user: User;
}>('ui/change-user-role-drawer');

export const showFileViewer = createAction<Document>('ui/show-file-viewer');
export const hideFileViewer = createAction('ui/hide-file-viewer');

export const showAssignClientModal = createAction<Client>('ui/show-assign-client-modal');

export const showExportClientDrawer = createAction(
  'ui/show-export-client-drawer',
);

export const showApproveAccountDrawer = createAction<Client>(
  'ui/show-approve-account-drawer',
);

export const showPEPReportDrawer = createAction<Client>(
  'ui/show-pep-report-drawer',
);

export const showToast = createAction<{
  message: ToastState['message'];
  severity?: ToastState['severity'];
  autoHide?: ToastState['autoHide'];
}>('ui/show-toast');
export const hideToast = createAction('ui/hide-toast');

export const hideModal = createAction<{
  modalName: ModalName;
  shouldRefreshData?: boolean;
}>('ui/hide-modal');
export const clearModalRefreshState = createAction<ModalName | undefined>(
  'ui/clear-modal-refresh-state',
);

export const resetUI = createAction('ui/reset');
