export const EMAIL_PATTERN =
  '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()[\\]\\.,;:\\s@"]+)*)|.(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

export const PASSWORD_PATTERN =
  '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\\#\\?\\!\\@\\$\\%\\^\\&\\*\\-\\.\\[\\]\\{\\}\\(\\)\\"\\/\\\\,\\>\\<\\\'\\:\\;\\|\\_\\~\\`\\+\\=]).{8,}$';

export const URL_PATTERN =
  '(https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&\\//=]*))';

export const MIME_PDF_PATTERN = 'application/pdf';
export const MIME_IMAGE_PATTERN = '^image/\\w+';
export const MIME_VIDEO_PATTERN =
  '^video/(mp4|webm|ogg|x-matroska|x-msvideo|x-ms-wmv|quicktime)$';
export const MIME_EXCEL_PATTERN =
  'application/(vnd\\.ms\\-excel|vnd\\.openxmlformats\\-officedocument\\.spreadsheetml\\.sheet)';
export const MIME_DOC_PATTERN =
  'application/(vnd\\.openxmlformats\\-officedocument\\.wordprocessingml\\.document)';

export const DATE_VALUE_FORMAT = 'yyyy-MM-dd';
