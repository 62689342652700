import 'core-js/proposals/promise-with-resolvers'; // Provide polyfill for Promise.withResolvers for pdfjs to work in older browsers
import { Alert, CssBaseline, ThemeProvider } from '@mui/material';
import { defaultTheme } from './styles';
import { Provider as ReduxProvider } from 'react-redux';
import {
  fetchOccupations,
  notifyUserChanged,
  resetClientDetails,
  resetPicklists,
  resetUI,
  selectAuthState,
  setLoggedInUser,
  setLoginResponseError,
  setTenant,
  store,
} from './redux';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { pdfjs } from 'react-pdf';
import { useEffect } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { getCurrentUser } from './api';
import { useDispatch, useSelector } from './hooks';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import { AMPLIFY_CONFIGS } from './aws-exports';
import './i18n/i18n';
import {
  OverlayScroller,
  FullPageLoaderOverlay,
  ErrorBoundary,
  FullPageCenter,
  Icon,
} from './components';
import 'simplebar-react/dist/simplebar.min.css';
import {
  LS_TENANT_KEY,
  VITE_ENVIRONMENT,
  VITE_PROD,
  VITE_RELEASE_TIME,
} from './constants';
import { Environment, Language, Tenant } from './types';
import { getAPIErrorMessage, readLocal } from './utils';
import { AuthenticatedRoutes, UnauthenticatedRoutes } from './pages/root';
import { useTranslate } from './hooks';

const queryClient = new QueryClient();

Amplify.configure(AMPLIFY_CONFIGS);

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

export function App() {
  if (
    VITE_PROD &&
    VITE_ENVIRONMENT === Environment.Development &&
    VITE_RELEASE_TIME
  ) {
    console.log(`[DEBUG] Deploy time: ${VITE_RELEASE_TIME}`);
  }

  return (
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <OverlayScroller variant="page">
              <AppRouter />
            </OverlayScroller>
          </ThemeProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </ReduxProvider>
  );
}

function AppRouter() {
  const { changeLanguage } = useTranslate();
  const { user, initialized, shouldRefreshUser } = useSelector(selectAuthState);

  const { data, refetch, isFetched, error } = useQuery({
    queryKey: ['currentUser'],
    queryFn: getCurrentUser,
    retry: false,
    refetchOnWindowFocus: false,
    gcTime: 0,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    error && dispatch(setLoginResponseError(getAPIErrorMessage(error)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    const cancelListen = Hub.listen('auth', data => {
      switch (data.payload.event) {
        case 'signedIn':
          dispatch(notifyUserChanged());
          break;
        case 'signedOut':
          dispatch(setLoggedInUser(null));
          dispatch(resetClientDetails());
          dispatch(resetPicklists());
          dispatch(resetUI());
          break;
      }
    });

    return () => cancelListen();
  }, [dispatch]);

  useEffect(() => {
    if (shouldRefreshUser) {
      refetch();
    }
  }, [refetch, shouldRefreshUser]);

  useEffect(() => {
    if (isFetched) {
      changeLanguage((data?.user?.language as Language) || Language.English);
      dispatch(setTenant(readLocal(LS_TENANT_KEY) as Tenant));
      dispatch(setLoggedInUser(data?.user));
      dispatch(fetchOccupations());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch, isFetched]);

  return (
    <ErrorBoundary
      fallback={error => (
        <FullPageCenter>
          <Alert icon={<Icon name="alert" />} color="error" variant="standard">
            {error?.message || 'An unexpected error occurred'}
          </Alert>
        </FullPageCenter>
      )}
    >
      {!initialized ? (
        <FullPageLoaderOverlay />
      ) : user ? (
        <AuthenticatedRoutes />
      ) : (
        <UnauthenticatedRoutes />
      )}
    </ErrorBoundary>
  );
}
