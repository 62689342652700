import { Document, FileFrameActionItem, IconName } from '@/types';
import {
  FileFrameAction,
  FileThumbnailContainer,
  FileViewerContainer,
  ViewerCenterSlot,
} from './File.styled';
import { Image } from './Image';
import { Icon, Loader } from '../Icon';
import { useDispatch, useDocument } from '@/hooks';
import { showFileViewer } from '@/redux';
import { Alert, Button, IconButton } from '@mui/material';
import {
  getDataTestId,
  isDocContentType,
  isExcelContentType,
  isImageContentType,
  isPDFContentType,
  isVideoContentType,
} from '@/utils';
import { PDFRenderer } from './PDFRenderer';
import { ExcelRenderer } from './ExcelRenderer';
import { DocRenderer } from './DocRenderer/DocRenderer';
import { ErrorBoundary } from '../Layout';
import { VideoPlayer } from './VideoPlayer';
import { useState } from 'react';

type FileFrameProps = {
  document: Document;
  disabledCache?: boolean;
  actionItems?:
    | FileFrameActionItem[]
    | ((defaultActions: FileFrameActionItem[]) => FileFrameActionItem[]);
};

export function FileFrame({
  document,
  disabledCache,
  actionItems,
}: FileFrameProps) {
  const { data, download } = useDocument({ document, disabledCache });
  const [showPDF, setShowPDF] = useState(true);
  const [showAction, setShowAction] = useState(true);
  const dispatch = useDispatch();

  const viewFile = () => {
    dispatch(showFileViewer(document));
  };

  const onToggleShowPDF = () => {
    setShowPDF(!showPDF);
    setShowAction(!showAction);
  };

  const renderViewer = () => {
    if (!data) return null;

    if (isImageContentType(document.contentType)) {
      return <Image src={data.localUrl || data.uri} />;
    } else if (isVideoContentType(document.contentType)) {
      return <VideoPlayer src={data.localUrl || data.uri} />;
    } else if (isPDFContentType(document.contentType)) {
      return showPDF ? (
        <PDFRenderer
          file={data.localUrl || data.uri || ''}
          renderTextLayer={false}
          onCancelPassword={onToggleShowPDF}
        />
      ) : (
        <ViewerCenterSlot>
          <Button color="error" onClick={onToggleShowPDF}>
            Retry
          </Button>
        </ViewerCenterSlot>
      );
    } else if (isExcelContentType(document.contentType)) {
      return <ExcelRenderer file={data.localUrl || data.uri || ''} />;
    } else if (isDocContentType(document.contentType)) {
      return <DocRenderer file={data.localUrl || data.uri || ''} />;
    }
  };

  const renderActions = () => {
    const defaultActions: FileFrameActionItem[] = [
      ...(!isVideoContentType(document.contentType)
        ? [
            {
              icon: 'zoom-in' as IconName,
              title: 'Maximize',
              onClick: viewFile,
            },
          ]
        : []),
      {
        icon: 'download',
        title: 'Download',
        onClick: download,
      },
    ];
    const actions = !actionItems
      ? defaultActions
      : Array.isArray(actionItems)
        ? actionItems
        : actionItems(defaultActions);

    return (
      <FileFrameAction>
        {actions.map(({ title, icon, onClick }) => (
          <IconButton
            key={`file-frame-action-${title}`}
            onClick={onClick}
            title={title}
          >
            <Icon name={icon} />
          </IconButton>
        ))}
      </FileFrameAction>
    );
  };

  if (data?.isLoading) {
    return (
      <FileViewerContainer>
        <FileThumbnailContainer
          {...getDataTestId(`file-thumbnail-${document.id}`)}
        >
          <ViewerCenterSlot>
            <Loader />
          </ViewerCenterSlot>
        </FileThumbnailContainer>
      </FileViewerContainer>
    );
  }

  return (
    <FileViewerContainer>
      <ErrorBoundary
        fallback={() => (
          <ViewerCenterSlot>
            <Alert
              icon={<Icon name="alert" />}
              color="error"
              variant="standard"
            >
              Something went wrong, cannot display document
            </Alert>
          </ViewerCenterSlot>
        )}
      >
        <>
          <FileThumbnailContainer
            {...getDataTestId(`file-thumbnail-${document.id}`)}
          >
            {renderViewer()}
          </FileThumbnailContainer>
          {showAction && renderActions()}
        </>
      </ErrorBoundary>
    </FileViewerContainer>
  );
}
