import { AppConfigurations } from '@/types';
import { defaultConfigs } from './default';

export const guyanaConfigs: AppConfigurations = {
  ...defaultConfigs,
  locale: 'en-GY',
  isoCode: 'GUY',
  currency: 'GYD',
  name: 'Guyana',
  flag: 'flag-guyana',
  requireLocalTin: true,
  autoApproveUnemployedUsers: true,
};
