import {
  FullHeightTextField,
  LoadingButton,
  SearchSelect,
  Select,
  TextInput,
} from '../../Form';
import { Form } from '../Modals.styled';
import { ClientBlock } from '../../User';
import { Button } from '@mui/material';
import { Icon } from '../../Icon';
import { FormAction } from '../../Layout';
import {
  getAPIErrorMessage,
  getDataTestId,
  getDisplayField,
  getDocumentSectionsByTenant,
  getDocumentTypesBySection,
  htmlizeText,
  replaceRequestDocumentTemplate,
} from '@/utils';
import { useBoolean } from 'usehooks-ts';
import { useDispatch, useForm, useSelector, useTranslate } from '@/hooks';
import {
  Client,
  ClientStatus,
  DocumentRequestForm,
  DocumentSection,
  DocumentType,
  Language,
  ModalName,
  Option,
} from '@/types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  getClients,
  getRequestDocumentTemplate,
  requestClientDocument,
} from '@/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  hideModal,
  selectClientAccountTypes,
  selectConfigsState,
  selectSigningDocuments,
  showToast,
} from '@/redux';
import { DOCUMENT_SECTION_TEXT, DOCUMENT_TYPE_TEXT } from '@/constants';

type RequestDocumentFormProps = {
  client?: Client;
  modalName: ModalName;
  onSetDisabled?: (value: boolean) => void;
};

export function RequestDocumentForm({
  client,
  modalName = ModalName.RequestDocument,
  onSetDisabled,
}: RequestDocumentFormProps) {
  const dispatch = useDispatch();
  const { i18n } = useTranslate();
  const selectedClient = useRef<Client>();
  const tenantConfigs = useSelector(selectConfigsState);
  const accountTypes = useSelector(selectClientAccountTypes);
  const [documentTypes, setDocumentTypes] = useState<Option[]>([]);

  const documentSections = useMemo(() => {
    return getDocumentSectionsByTenant(tenantConfigs);
  }, [tenantConfigs]);

  const formsToSign = useSelector(
    selectSigningDocuments({
      client: (client || selectedClient.current) as Client,
      clientAccountTypes: accountTypes,
      tenant: tenantConfigs.tenant,
    }),
  );

  const {
    value: isTemplatedUpdated,
    setTrue: templateUpdated,
    setFalse: resetTemplateChanges,
  } = useBoolean(false);

  const {
    control,
    handleSubmit,
    setValue,
    visibleErrors: errors,
    watch,
    formState: { dirtyFields, isValid },
    trigger,
    resetField,
  } = useForm<DocumentRequestForm>({
    defaultValues: {
      clientId: client?.id || '',
      section: DocumentSection.ProofOfAddress,
      type: '' as DocumentType,
      message: '',
    },
  });

  const clientId = watch('clientId');
  const message = watch('message');
  const section = watch('section');
  const type = watch('type');

  const { data: messageTemplate, isLoading: isLoadingTemplate } = useQuery({
    queryKey: ['request-document-message-template', i18n.language],
    queryFn: () => getRequestDocumentTemplate(i18n.language as Language),
  });

  const { mutate: sendRequest, isPending: isSending } = useMutation({
    mutationFn: requestClientDocument,
    onSuccess: response => {
      dispatch(
        showToast({
          message: response.message,
          severity: 'success',
        }),
      );
      dispatch(hideModal({ modalName }));
    },
    onError: error => {
      dispatch(
        showToast({
          message: getAPIErrorMessage(error),
          severity: 'error',
        }),
      );
    },
  });

  const searchClients = useCallback((keyword: string) => {
    return getClients({
      pagination: {
        page: 0,
        size: 20,
        total: 20,
      },
      query: keyword,
      filter: {
        status: [
          ClientStatus.InReview,
          ClientStatus.PendingApproval,
          ClientStatus.Draft,
        ],
      },
      excludeFields: ['documents'],
    });
  }, []);

  const clientToOption = useCallback(
    (client: unknown) =>
      ({
        value: (client as Client)?.id,
        text: getDisplayField(client as Client),
      }) as Option,
    [],
  );

  const onClientSelected = useCallback((_clientId: string, client: unknown) => {
    selectedClient.current = client as Client;
  }, []);

  useEffect(() => {
    const newDocumentTypes = getDocumentTypesBySection(section, {
      formsToSign,
    });

    resetField('type', {
      defaultValue:
        (newDocumentTypes[0]?.value as DocumentType) || DocumentType.None,
    });

    setDocumentTypes(newDocumentTypes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);

  useEffect(() => {
    if (client?.id) {
      setValue('clientId', client.id);
    }
    selectedClient.current = client;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    if (
      (!message.length && isTemplatedUpdated) ||
      !message ||
      (message && !dirtyFields['message'])
    ) {
      setValue(
        'message',
        replaceRequestDocumentTemplate({
          message: messageTemplate || '',
          section: section || '',
          type: type || DocumentType.None,
          client: selectedClient.current,
          tenant: tenantConfigs.tenant,
        }),
      );
      resetTemplateChanges();

      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageTemplate, clientId, type, section]);

  useEffect(() => {
    if (
      dirtyFields['message'] &&
      message.length > 0 &&
      messageTemplate &&
      message !== messageTemplate
    ) {
      templateUpdated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    onSetDisabled?.(isSending);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSending]);

  const onSubmit = handleSubmit(payload => {
    const documentNames = [
      ...formsToSign.map(form => form.name),
      ...Object.values(DOCUMENT_TYPE_TEXT),
      ...Object.values(DOCUMENT_SECTION_TEXT).flatMap(section =>
        Object.values(section),
      ),
    ];

    sendRequest({
      ...payload,
      message: htmlizeText(payload.message).replaceAll(
        new RegExp(`(${documentNames.join('|')})`, 'g'),
        '<b>$&</b>',
      ),
    });
  });

  const onClose = () => dispatch(hideModal({ modalName }));

  return (
    <Form onSubmit={onSubmit}>
      {client ? (
        <TextInput label="Client" value={getDisplayField(client)} disabled />
      ) : (
        <SearchSelect
          control={control}
          name="clientId"
          searchQueryKey="request-document-client-id"
          rules={{
            required: 'Select client',
          }}
          searchFn={searchClients}
          valueToOption={clientToOption}
          onSelected={onClientSelected}
          textFieldProps={{
            label: 'Client',
            error: !!errors.clientId,
            helperText: errors.clientId?.message,
          }}
          defaultValues={client ? [client] : []}
          renderOption={(_, item) => (
            <ClientBlock
              avatar={(item as Client)?.picture}
              name={getDisplayField(item as Client)}
              description={(item as Client)?.email}
            />
          )}
          disabled={isSending}
        />
      )}
      <Select
        options={documentSections}
        label="Category"
        control={control}
        name="section"
        fullWidth
        rules={{
          required: 'Category is required',
        }}
        error={!!errors.type}
        helperText={errors.type?.message}
        disabled={isSending}
      />
      <Select
        options={documentTypes}
        label="Document type"
        control={control}
        name="type"
        fullWidth
        error={!!errors.type}
        helperText={errors.type?.message}
        disabled={isSending || !section || documentTypes.length <= 1}
      />
      <FullHeightTextField
        name="message"
        control={control}
        rules={{
          required: 'Message is required',
        }}
        placeholder={
          isLoadingTemplate
            ? 'Loading template...'
            : 'Write request messages here'
        }
        disabled={isLoadingTemplate || isSending}
        error={!!errors.message}
        helperText={errors.message?.message}
      />
      <FormAction align="stretch">
        <Button
          {...getDataTestId(`request-document-action-cancel-button`)}
          onClick={onClose}
          variant="outlined"
          disabled={isSending}
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          disabled={!isValid}
          isLoading={isSending}
          startIcon={<Icon name="paper-plane" />}
          {...getDataTestId(`request-document-action-send-button`)}
        >
          Send request
        </LoadingButton>
      </FormAction>
    </Form>
  );
}
