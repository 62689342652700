import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useURLParams } from './useURLParams';
import { useForm } from './useForm';
import {
  DeepPartialSkipArrayKey,
  DefaultValues,
  FieldValues,
  Path,
  PathValue,
  useWatch,
} from 'react-hook-form';
import { ApiFilter, Client, DataList } from '@/types';
import { useEffect, useMemo } from 'react';
import { useDebounceValue, useIntersectionObserver } from 'usehooks-ts';
import { DEFAULT_PAGINATION } from '@/constants';

type UseInfiniteTableProps<T, F extends FieldValues = FieldValues> = {
  defaultValues?:
    | DefaultValues<F>
    | ((
        getUrlValue: (
          key: string,
          castType?: 'array' | 'string' | 'number' | 'boolean',
        ) => string | number | boolean | string[] | null | undefined,
      ) => DefaultValues<F>);
  onModifyFilter?: (filter: DeepPartialSkipArrayKey<F>) => Partial<F>;
  queryKeys?: string[];
  queryFn: (filter: ApiFilter) => Promise<DataList<T>>;
  getQueryFilter?: (filter: DeepPartialSkipArrayKey<F>) => Partial<ApiFilter>;
  doNotPassFilterToUrl?: boolean;
};

export function useInfiniteTable<T, F extends FieldValues = FieldValues>({
  defaultValues,
  onModifyFilter,
  queryKeys = [],
  queryFn,
  getQueryFilter,
  doNotPassFilterToUrl = false,
}: UseInfiniteTableProps<T, F>) {
  const queryClient = useQueryClient();
  const { updateSearchParams, getValue } = useURLParams();
  const { control, setValue, watch } = useForm<F>({
    defaultValues: {
      keyword: !doNotPassFilterToUrl
        ? (getValue('keyword', 'string') as string)
        : '',
      sortBy: !doNotPassFilterToUrl
        ? (getValue('sortBy', 'string') as string)
        : '',
      ...(typeof defaultValues === 'function'
        ? defaultValues(getValue)
        : defaultValues),
    },
  });

  const filter = useWatch({ control });

  const modifiedFilter = useMemo(
    () => ({
      ...filter,
      keyword: filter.keyword?.trim() || '',
      ...onModifyFilter?.(filter),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter],
  );
  const [debouncedFilter] = useDebounceValue(modifiedFilter, 500);

  useEffect(() => {
    if (!doNotPassFilterToUrl) {
      updateSearchParams(debouncedFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilter]);

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [...queryKeys, getQueryFilter, queryFn, debouncedFilter],
    queryFn: ({ pageParam }) =>
      queryFn({
        pagination: { ...DEFAULT_PAGINATION, page: pageParam },
        query: debouncedFilter.keyword,
        sort: debouncedFilter.sortBy,
        ...getQueryFilter?.(debouncedFilter),
      }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      return lastPage.data.length < lastPage.size
        ? null
        : lastPage.page !== undefined
          ? lastPage.page + 1
          : 0;
    },
    retry: false,
    gcTime: 0,
  });

  const { isIntersecting, ref: nextPageTriggerRef } = useIntersectionObserver({
    threshold: 1,
  });

  useEffect(() => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchNextPage, data, isIntersecting]);

  const onSortChange = (dataKey: string) => {
    setValue('sortBy' as Path<F>, dataKey as PathValue<F, Path<F>>);
  };

  const updateClientRecord = (
    id: string | number,
    updatedData: Partial<Client>,
  ) => {
    const queryKey = [...queryKeys, getQueryFilter, queryFn, debouncedFilter];

    queryClient.setQueryData(
      queryKey,
      (existingRecords: { pages: { data: Client[] }[] } | undefined) => {
        if (!existingRecords) return existingRecords;

        return {
          ...existingRecords,
          pages: existingRecords.pages.map(page => ({
            ...page,
            data: page.data.map(item =>
              item.id === id ? { ...item, ...updatedData } : item,
            ),
          })),
        };
      },
    );
  };
  const rows = data?.pages.reduce((acc, i) => [...acc, ...i.data], [] as T[]);

  return {
    rows,
    total: data?.pages[0].total,
    debouncedFilter,
    control,
    setValue,
    watch,
    onSortChange,
    nextPageTriggerRef,
    error,
    isFetching,
    isFetchingNextPage,
    refetch,
    getUrlValue: getValue,
    updateClientRecord,
  };
}
