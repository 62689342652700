import {
  AMLStatus,
  ClientStatus,
  DocumentStatus,
  DocumentType,
  EmploymentStatus,
  Gender,
  GenericStatus,
  IconName,
  JobStatus,
  JobType,
  PEPStatus,
  Profession,
  RoleName,
  UserStatus,
  OptionValue,
  FundingSource,
  ClientTitle,
  CautionCheckStatus,
  DocumentSection,
  Tenant,
} from '@/types';

export const ClientTitleText: Record<string, string> = {
  [ClientTitle.MR]: 'Mr.',
  [ClientTitle.MS]: 'Ms.',
  [ClientTitle.MRS]: 'Mrs.',
};

export const ClientStatusText: Record<string, string> = {
  [ClientStatus.Draft]: 'Draft',
  [ClientStatus.InReview]: 'In Review',
  [ClientStatus.Rejected]: 'Rejected',
  [ClientStatus.PendingApproval]: 'Pending approval',
  [ClientStatus.Approved]: 'Approved',
  [ClientStatus.Archived]: 'Archived',
};

export const StatusText: Record<string, string> = {
  [ClientStatus.Archived]: 'Archived',
  [DocumentStatus.AboutToExpire]: 'About to expire',
  [DocumentStatus.Rejected]: 'Rejected',
  [DocumentStatus.Approved]: 'Approved',
  [DocumentStatus.Expired]: 'Expired',
  [DocumentStatus.Requested]: 'Requested',
  [DocumentStatus.Pending]: 'Pending',
  [DocumentStatus.InReview]: 'In Review',
  [DocumentStatus.Generated]: 'Generated',
  [PEPStatus.Exposed]: 'Exposed',
  [PEPStatus.Verified]: 'Verified',
  [PEPStatus.InProgress]: 'In progress',
  [PEPStatus.Approved]: 'Approved',
  [AMLStatus.Sanctioned]: 'Sanctioned',
  [AMLStatus.Verified]: 'Verified',
  [AMLStatus.InProgress]: 'In progress',
  [AMLStatus.Approved]: 'Approved',
  [GenericStatus.Verified]: 'Verified',
  [GenericStatus.Unverified]: 'Unverified',
  [CautionCheckStatus.Matched]: 'Matched',
  [CautionCheckStatus.UnMatched]: 'Unmatched',
};

export const StatusIcon: Record<string, IconName> = {
  [DocumentStatus.Pending]: 'file-dash',
  [DocumentStatus.InReview]: 'files',
  [DocumentStatus.Rejected]: 'reject',
  [DocumentStatus.AboutToExpire]: 'alert-outline',
  [DocumentStatus.Expired]: 'siren',
  [DocumentStatus.Approved]: 'check-fat-outline',
  [DocumentStatus.Generated]: 'gear-fine',
};

export const DOCUMENT_SECTION_TEXT: Partial<
  Record<DocumentSection, Partial<Record<Tenant | 'default', string>>>
> = {
  [DocumentSection.TIN]: {
    default: 'TIN',
    [Tenant.Guyana]: 'Guyana TIN',
  },
  [DocumentSection.ProofOfAddress]: { default: 'Proof of Address' },
  [DocumentSection.ProofOfEmployment]: { default: 'Proof of Employment' },
  [DocumentSection.DocumentSign]: { default: 'Document Sign' },
  [DocumentSection.Identity]: { default: 'Identity' },
  [DocumentSection.IdentityBack]: { default: 'Identity Back' },
  [DocumentSection.Selfie]: { default: 'Selfie' },
  [DocumentSection.Internal]: { default: 'Internal Document' },
  [DocumentSection.BranchApproval]: { default: 'Branch approval documents' },
  [DocumentSection.Generated]: { default: 'Generated Document' },
  [DocumentSection.ProofOfIncome]: { default: 'Proof of Income' },
  [DocumentSection.SecondID]: { default: 'Second ID' },
  [DocumentSection.IDVideo]: { default: 'Identity Video' },
};

export const DOCUMENT_TYPE_TEXT: Record<string, string> = {
  [DocumentType.UtilityBill]: 'Utility Bill in my name',
  [DocumentType.UtilityBillNonOwner]: 'Utility Bill not in my name',
  [DocumentType.BankStatement]: 'Bank Statement',
  [DocumentType.GemeenteUitreksel]: 'Gemeente Uitreksel',
  [DocumentType.CertificateOfTitle]: 'Certificate of Title',
  [DocumentType.FireInsurance]: 'Fire Insurance',
  [DocumentType.RatesAndTaxesReceiptInvoice]: 'Rates and Taxes receipt/invoice',
  [DocumentType.TinCertificate]: 'TIN Certificate',
  [DocumentType.LetterFromToshaosHinterlandLocations]:
    'Letter from Toshaos (Hinterland locations)',
  [DocumentType.PostMarkedEnvelopeWithCustomerNameAndAddress]:
    "Post marked envelope with customer's name and address",
  [DocumentType.LeaseAgreement]: 'Lease Agreement',
  [DocumentType.LetterOfAuthorisationFromUtilityBillOwner]:
    'Letter of Authorisation from Utility Bill Owner',
  [DocumentType.RegistrationForm]: 'Registration Form',
  [DocumentType.CBBExtract]: 'CBB Extract',
  [DocumentType.CopyOfUtilityBillOwnerID]: "Copy of Utility Bill Owner's ID",
  [DocumentType.SelfAttestationForProofOfAddress]:
    'Self-Attestation For Proof of Address',
  [DocumentType.ProofOfPropertyOwnershipNotarialDeed]:
    'Proof of Property Ownership (Notarial Deed)',
  [DocumentType.BankReferenceLetter]: 'Bank Reference Letter',
  [DocumentType.ConfirmationOfAddress]: 'Confirmation of Address',
  [DocumentType.DriverLicense]: `Driver's License`,
  [DocumentType.LetterOfEmployment]: 'Letter of Employment',
  [DocumentType.RecentPayslip]: 'Recent Payslip',
  [DocumentType.LatestFiledTaxReturn]: 'Latest Filed Tax Return',
  [DocumentType.FinancialStatements]: 'Financial Statements',
  [DocumentType.FinancialStatementsProjections]:
    'Financial Statements / Projections',
  [DocumentType.MonthlyIncomeAndExpenditure]: 'Monthly Income And Expenditure',
  [DocumentType.ManagementAccounts]: 'Management Accounts',
  [DocumentType.IncomeStatement]: 'Income Statement',
  [DocumentType.SchoolLetterOfAttendance]: 'School Letter of Attendance',
  [DocumentType.SupportLetterFromParent]: 'Support Letter from Parent',
  [DocumentType.ParentID]: 'Parent ID',
  [DocumentType.AuthorisationLetter]: 'Authorisation Letter',
  [DocumentType.SocialSecurityLetter]: 'Social Security Letter',
  [DocumentType.SupportLetterFromHusband]: 'Support Letter from Husband',
  [DocumentType.SupportLetterFromCaretaker]: 'Support Letter from Caretaker',
  [DocumentType.ProfessionalRecommendationLetter]:
    'Professional Recommendation Letter',
  [DocumentType.TradeLicense]: 'Trade License',
  [DocumentType.ForecastProjections]: 'Forecast/Projections',
  [DocumentType.ProjectedCashFlows]: 'Projected Cash Flows',
  [DocumentType.IncomeAndExpenditureStatement]:
    'Income and Expenditure Statement',
  [DocumentType.SelfAttestationForProofOfIncome]:
    'Self-Attestation for Proof of Income',
  [DocumentType.ExtractFromChamberOfCommerce]:
    'Extract from Chamber of Commerce',

  [DocumentType.Id]: 'ID',
  [DocumentType.SecurityCard]: 'Security card',
  [DocumentType.Passport]: 'Passport',
  [DocumentType.Nis]: 'NIS',
  [DocumentType.IdentityDriverLicense]: `Driver's License`,
  [DocumentType.IDC]: 'IDC Form',
  [DocumentType.AML5]: 'AML5 Customer Risk Rating Form',
  [DocumentType.OneCardOrder]: 'RBL Onecard Order Form',
  [DocumentType.Unknown]: 'Document',
  [DocumentType.VideoAddSelfie]: 'Video Selfie Submission',
};

export const ProfessionText: Record<string, string> = {
  [Profession.Housewife]: 'Housewife',
  [Profession.PartnershipLimitedLiability]: 'Partnership/Limited Liability',
  [Profession.Pensioner]: 'Pensioner',
  [Profession.SoleTrader]: 'Sole Trader',
  [Profession.Student]: 'Student',
  [Profession.Unemployed]: 'Unemployed',
  [Profession.Unincorporated]: 'Unincorporated',
};

export const EmploymentStatusText: Record<EmploymentStatus, string> = {
  [EmploymentStatus.Employed]: 'Employed',
  [EmploymentStatus.SelfEmployed]: 'Self-employed',
  [EmploymentStatus.Unemployed]: 'Unemployed',
};

export const GenderText: Record<string, string> = {
  [Gender.Female]: 'Female',
  [Gender.Male]: 'Male',
};

export const JobStatusText: Record<JobStatus, string> = {
  [JobStatus.Pending]: 'Pending',
  [JobStatus.Processing]: 'Processing',
  [JobStatus.Error]: 'Error',
  [JobStatus.Success]: 'Success',
};

export const UserStatusText: Record<string, string> = {
  [UserStatus.Active]: 'Active',
  [UserStatus.Locked]: 'Locked',
};

export const RoleNameText: Record<string, string> = {
  [RoleName.Admin]: 'Administrator',
  [RoleName.ProductionSupport]: 'Production Support User',
  [RoleName.Corporate]: 'Corporate Security User',
  [RoleName.Approver]: 'Manual Review Approver',
  [RoleName.ManualReviewer]: 'Manual Review User',
  [RoleName.ViewOnly]: 'View Only User',
  [RoleName.ManualReviewerSupervisor]: 'Manual Reviewer Supervisor',
};

export const ScheduleJobTypeText: Record<string, string> = {
  [JobType.CreateBankAccount]: 'Create Bank Account',
  [JobType.CreateICBProfile]: 'Create ICB Profile',
  [JobType.CreateRIM]: 'Create RIM',
  [JobType.FundAccount]: 'Fund Account',
  [JobType.UpdateRIM]: 'Update RIM',
};

export const OptionValueText: Record<string, string> = {
  [OptionValue.Yes]: 'Yes',
  [OptionValue.No]: 'No',
  [OptionValue.All]: 'All',
};

export const FundingSourceText = {
  [FundingSource.Salary]: 'Salary',
  [FundingSource.Savings]: 'Savings',
  [FundingSource.FundingFromSpouse]: 'Funds from Spouse',
  [FundingSource.FundingFromParents]: 'Funds from Parents',
  [FundingSource.BusinessProceeds]: 'Business Proceeds',
  [FundingSource.Investments]: 'Investments',
  [FundingSource.Other]: 'Other',
};
