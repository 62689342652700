import { AppConfigurations, DocumentSection } from '@/types';
import { defaultConfigs } from './default';

export const trinidadConfigs: AppConfigurations = {
  ...defaultConfigs,
  locale: 'en-TT',
  isoCode: 'TTO',
  currency: 'TTD',
  name: 'Trinidad & Tobago',
  flag: 'flag-trinidad-and-tobago',
  requiredDocumentSections: [
    ...defaultConfigs.requiredDocumentSections,
    DocumentSection.SecondID,
  ],
  autoApproveUnemployedUsers: true,
};
