import {
  Box,
  MenuProps,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { MenuButton, MenuDivider, StyledMenu } from './User.styled';
import { Icon } from '../Icon';
import { UserMenuOption } from './User.interface';
import { ClientBlock } from '.';
import { getDataTestId } from '@/utils';
import { Language, Tenant, User } from '@/types';
import { useSelector, useTranslate } from '@/hooks';
import { selectConfigsState } from '@/redux';

type UserMenuProps = {
  onOptionClick: (option: UserMenuOption, language?: Language) => void;
  loggedInUser: User;
} & MenuProps;

export function UserMenu({
  onOptionClick,
  loggedInUser,
  ...props
}: UserMenuProps) {
  const { tenant } = useSelector(selectConfigsState);
  const { i18n } = useTranslate();

  return (
    <StyledMenu {...getDataTestId('user-header-menu')} {...props}>
      <Box p={2} pb={0}>
        <ClientBlock
          avatar={loggedInUser.picture}
          name={loggedInUser.fullName}
          description={loggedInUser.email}
        />
      </Box>
      <Stack direction="column" alignItems="stretch" p={2} pt={1.5} gap={0.5}>
        <MenuButton
          onClick={() => onOptionClick('change-avatar')}
          {...getDataTestId('change-avatar-button')}
        >
          Upload picture
        </MenuButton>
        <MenuButton
          onClick={() => onOptionClick('change-password')}
          {...getDataTestId('change-password-button')}
        >
          Change password
        </MenuButton>
        {tenant === Tenant.Suriname && (
          <>
            <MenuDivider />
            <MenuButton {...getDataTestId('change-language-button')}>
              <Typography flex={1} align="left">
                Language
              </Typography>
              <ToggleButtonGroup
                value={i18n.language}
                exclusive
                onChange={(_: unknown, value: Language) =>
                  onOptionClick('change-language', value)
                }
              >
                <ToggleButton value={Language.English}>
                  <Typography>EN</Typography>
                </ToggleButton>
                <ToggleButton value={Language.Dutch}>
                  <Typography>NL</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </MenuButton>
          </>
        )}
        <MenuDivider />
        <MenuButton
          onClick={() => onOptionClick('sign-out')}
          startIcon={<Icon name="sign-out" />}
          {...getDataTestId('sign-out-button')}
        >
          Sign out
        </MenuButton>
      </Stack>
    </StyledMenu>
  );
}
