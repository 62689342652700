import {
  defaultConfigs,
  DOCUMENT_SECTION_TEXT,
  DOCUMENT_TYPE_TEXT,
  MIME_DOC_PATTERN,
  MIME_EXCEL_PATTERN,
  MIME_IMAGE_PATTERN,
  MIME_PDF_PATTERN,
  MIME_VIDEO_PATTERN,
  URL_PATTERN,
} from '@/constants';
import { Client, DocumentSection, DocumentType, Tenant } from '@/types';
import { Path, createSearchParams, generatePath } from 'react-router-dom';
import { getDisplayField } from './api';

export function getAvatarLetters(name: string) {
  if (!name || name.length === 1) return name;

  const words = name.split(' ').filter(i => !!i);

  if (words.length === 1) {
    return `${name[0]}${name[1]}`;
  }

  return `${words[0][0]}${words[1][0]}`;
}

export function getNavigateObject(
  path: string,
  options: {
    params?: Record<string, string | number>;
    queries?: Record<string, string>;
  } = {},
): Partial<Path> {
  const { params = {}, queries } = options;

  return {
    pathname: generatePath(path, params),
    ...(queries ? { search: createSearchParams(queries).toString() } : {}),
  };
}

export function isImageContentType(contentType?: string) {
  if (!contentType) return false;

  return new RegExp(MIME_IMAGE_PATTERN, 'g').test(contentType);
}

export function isPDFContentType(contentType?: string) {
  if (!contentType) return false;

  return new RegExp(MIME_PDF_PATTERN, 'g').test(contentType);
}

export function isExcelContentType(contentType?: string) {
  if (!contentType) return false;

  return new RegExp(MIME_EXCEL_PATTERN, 'g').test(contentType);
}

export function isDocContentType(contentType?: string) {
  if (!contentType) return false;
  return new RegExp(MIME_DOC_PATTERN, 'g').test(contentType);
}

export function isVideoContentType(contentType?: string) {
  if (!contentType) return false;

  return new RegExp(MIME_VIDEO_PATTERN, 'g').test(contentType);
}

export function getDisplayedPhone(phone?: string) {
  if (!phone) return '';

  return phone.startsWith('+') ? phone : `+${phone}`;
}

export function htmlizeText(text: string) {
  return text
    .replaceAll(/(\r?\n)/g, '<br />')
    .replaceAll(
      new RegExp(URL_PATTERN, 'g'),
      '<a target="_blank" href="$&">$&</a>',
    );
}

export function replaceRequestDocumentTemplate({
  message,
  client,
  type,
  section,
  tenant,
}: {
  message: string;
  client?: Client;
  type: DocumentType;
  section: DocumentSection;
  tenant: string;
}) {
  const documentName =
    section === DocumentSection.DocumentSign
      ? type
      : type === DocumentType.None
        ? DOCUMENT_SECTION_TEXT[section]?.[tenant as Tenant | 'default'] ||
          DOCUMENT_SECTION_TEXT[section]?.default
        : DOCUMENT_TYPE_TEXT[type];

  return message
    .replaceAll(
      '{client_name}',
      getDisplayField(client, {
        nameOnly: true,
      }),
    )
    .replaceAll(/\s+,/g, ',')
    .replaceAll('{document}', documentName || '');
}

export function formatCurrency(
  amount: number,
  options: {
    locale?: string;
    currency?: string;
  } = {},
) {
  return amount.toLocaleString(options.locale || defaultConfigs.locale, {
    style: 'decimal',
    currency: options.currency || defaultConfigs.currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
}
