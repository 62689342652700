import { AssignClientModalForm } from './Modals.styled';
import { FormAction, FormCard } from '../Layout';
import { LoadingButton, Select } from '../Form';
import { ModalProps } from './Modals.interface';
import { Alert, Box, Modal } from '@mui/material';
import { AssignClientForm, Client, ModalName, Option } from '@/types';
import { getAPIErrorMessage, getDataTestId, getDisplayField } from '@/utils';
import { useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import { ClientBlock } from '../User';
import { Icon } from '../Icon';
import { useForm, useModal, useSelector } from '@/hooks';
import { selectLoggedInUser, selectReviewers } from '@/redux';
import { useMutation } from '@tanstack/react-query';
import { assignClient } from '@/api';
import { AbilityContext } from '../Can';

type AssignClientModalProps = {
  client?: Client;
  onAssigned?: (id: string | number, updateData: Partial<Client>) => void;
  onClose?: () => void;
} & Omit<ModalProps, 'children'>;

export function AssignClientModal({
  client,
  onAssigned,
  onClose,
  ...props
}: AssignClientModalProps) {
  const { hide, hideAndRefresh } = useModal({ name: ModalName.AssignClient });
  const ability = useContext(AbilityContext);
  const loggedInUser = useSelector(selectLoggedInUser);
  const reviewers = useSelector(selectReviewers);
  const canAssignBulk = ability.can('assign', 'onboarding_application');
  const canAssignSelf = ability.can('assign', 'onboarding_application_self');
  const canUnassignSelf = ability.can(
    'unassign',
    'onboarding_application_self',
  );
  const canUnassign = ability.can('unassign', 'onboarding_application');
  const {
    control,
    handleSubmit,
    reset: resetForm,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<AssignClientForm>({
    defaultValues: {
      clientId: client?.id,
      reviewerId: client?.reviewBy || '',
      message: '',
    },
  });

  const reviewerId = watch('reviewerId');
  const isSameReviewerSelected = reviewerId === client?.reviewBy;

  const {
    isSuccess,
    mutate,
    isPending: isAssinging,
    error,
    reset,
  } = useMutation({
    mutationFn: assignClient,
  });

  const userOptions: Option[] = useMemo(() => {
    const selfOption: Option = {
      value: loggedInUser?.id || '',
      text: getDisplayField(loggedInUser),
    };
    const options = [
      ...reviewers.map(reviewer => ({
        value: reviewer.id,
        text: getDisplayField(reviewer),
      })),
    ];
    const currentOption =
      client?.reviewBy && client.reviewBy !== loggedInUser?.id
        ? options.find(item => item.value === client.reviewBy)
        : undefined;

    if (!canAssignBulk) {
      return [selfOption, ...(currentOption ? [currentOption] : [])];
    }

    return [
      selfOption,
      ...options.filter(item => item.value !== selfOption.value),
    ];
  }, [canAssignBulk, client?.reviewBy, loggedInUser, reviewers]);

  useLayoutEffect(() => {
    if (props.open) {
      resetForm({
        clientId: client?.id || '',
        reviewerId: client?.reviewBy || '',
      });
      if (
        !client?.reviewBy &&
        canAssignSelf &&
        !canAssignBulk &&
        loggedInUser?.id
      ) {
        setValue('reviewerId', loggedInUser.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, props.open, resetForm]);

  useEffect(() => {
    if (isSuccess) {
      if (client) {
        const reviewerIdToUpdate =
          !client?.reviewBy || reviewerId !== client.reviewBy
            ? reviewerId
            : null;
        const updatedAssignee = userOptions.find(
          item => item.value === reviewerIdToUpdate,
        );
        onAssigned &&
          onAssigned(client.id, {
            assignee: updatedAssignee?.text || undefined,
          });
      }

      reset();
      hideAndRefresh();
      onClose && onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const onSubmit = handleSubmit(form => {
    mutate({
      clientId: form.clientId,
      reviewerId:
        !client?.reviewBy || form.reviewerId !== client.reviewBy
          ? form.reviewerId
          : null,
      message: form.message,
    });
  });

  const closeModal = () => {
    hide();
    onClose && onClose();
  };
  return (
    <Modal {...getDataTestId('assign-client-modal')} {...props}>
      <Box>
        <FormCard title="Assign client" onClose={closeModal} center>
          <AssignClientModalForm onSubmit={onSubmit}>
            {client && (
              <ClientBlock
                name={getDisplayField(client)}
                avatar={client.picture}
                description={client.rim ? `RIM # ${client.rim}` : undefined}
                direction="column"
              />
            )}
            <Select
              options={userOptions}
              control={control}
              name="reviewerId"
              label="Select user"
              fullWidth
              withSearchBar
              disabled={isAssinging || userOptions.length <= 1}
              rules={{
                required: 'Reviewer is required',
              }}
            />
            {error && (
              <Alert
                icon={<Icon name="alert" />}
                color="error"
                variant="standard"
              >
                {getAPIErrorMessage(error)}
              </Alert>
            )}
            <FormAction align="stretch">
              <LoadingButton
                variant="outlined"
                {...getDataTestId(`assign-client-modal-cancel-button`)}
                onClick={closeModal}
                disabled={isAssinging}
              >
                Cancel
              </LoadingButton>
              {isSameReviewerSelected ? (
                (canUnassign ||
                  (canUnassignSelf && reviewerId === loggedInUser?.id)) && (
                  <LoadingButton
                    type="submit"
                    {...getDataTestId(`assign-client-modal-unassign-button`)}
                    disabled={isAssinging}
                    isLoading={isAssinging}
                  >
                    Unassign
                  </LoadingButton>
                )
              ) : canAssignBulk || canAssignSelf ? (
                <LoadingButton
                  type="submit"
                  {...getDataTestId(`assign-client-modal-assign-button`)}
                  disabled={isAssinging || !isValid}
                  isLoading={isAssinging}
                >
                  Assign
                </LoadingButton>
              ) : null}
            </FormAction>
          </AssignClientModalForm>
        </FormCard>
      </Box>
    </Modal>
  );
}
