import { Language, Translation } from '@/types';
import { getCountryTranslations } from '@/utils';

const { country, nationality } = getCountryTranslations(Language.English);

export const ENGLISH: Translation = {
  location: {
    nationality,
    country: {
      ...country,
      'VGB-shorten': 'BVI',
    },
  },
};
