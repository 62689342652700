import { Alert } from '@mui/material';
import { Component, ReactNode } from 'react';
import { Icon } from '../Icon';

/*
Currently, React doesn't have a direct equivalent to componentDidCatch or getDerivedStateFromError for function components so we still use Class Component here and wait for the upcoming React RFC about error boundaries in function components to be implemented.
*/

type Props = {
  children: ReactNode;
  fallback?: (error: Error | null) => ReactNode;
};

type State = {
  hasError: boolean;
  error: Error | null;
};

type ErrorInfo = {
  componentStack: string;
};

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Error caught by boundary:', error);
    console.error('Component stack:', errorInfo.componentStack);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback(this.state.error);
      }

      return (
        <Alert icon={<Icon name="alert" />} color="error" variant="standard">
          {this.state.error?.message || 'An unexpected error occurred'}
        </Alert>
      );
    }

    return this.props.children;
  }
}
