import {
  Can,
  DocumentAccordion,
  DocumentAccordionFooter,
  FormAction,
  FormCard,
  Icon,
  LoadingButton,
  DocumentAccordionItemContainer,
} from '@/components';
import { useDispatch, useSelector } from '@/hooks';
import {
  fetchClientDetails,
  selectClientDetails,
  selectConfigsState,
} from '@/redux';
import {
  formatDate,
  getAPIErrorMessage,
  getBasicInfoStatus,
  getDataTestId,
  isActiveClient,
} from '@/utils';
import { Alert, Box, Grid, Modal, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useBoolean } from 'usehooks-ts';
import {
  BasicInfoApproveButton,
  PepAnswerDetails,
} from './ClientDetails.styled';
import { approveBasicInfo } from '@/api';
import { CountryCode3, PepAnswerStatus, QuestionCode } from '@/types';
import { useTranslate } from '@/hooks';

export function BasicInfoAccordion() {
  const { t } = useTranslate();
  const { dateFormat } = useSelector(selectConfigsState);
  const client = useSelector(selectClientDetails);
  const {
    value: isConfirmingBasicInfo,
    setTrue: showConfirmBasicInfoModal,
    setFalse: hideConfirmBasicInfoModal,
  } = useBoolean(false);
  const dispatch = useDispatch();

  const basicInfoStatus = useMemo(() => getBasicInfoStatus(client), [client]);

  const {
    mutate,
    isSuccess: isApproveBasicInfoSuccess,
    isPending,
    error: approveBasicInfoError,
    reset,
  } = useMutation({
    mutationFn: approveBasicInfo,
  });

  useEffect(() => {
    if (isApproveBasicInfoSuccess && client?.id) {
      dispatch(fetchClientDetails(String(client.id)));
      hideConfirmBasicInfoModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApproveBasicInfoSuccess]);

  const onApproveBasicInfo = () => {
    if (client && client.pepAnswer?.id) {
      reset();
      mutate({
        clientId: client.id,
        answerId: client.pepAnswer.id,
      });
    }
  };

  // TODO: Refactor this function later after confirming with backend
  const mappedPEPAnswer = (client?.pepAnswer?.answer?.answers || []).map(
    answer => {
      const entry = (client?.pepAnswerEntries || []).find(
        entry => entry.questionId === answer.questionId,
      );
      return {
        ...answer,
        code: entry?.code,
      };
    },
  );

  return (
    <>
      <DocumentAccordion
        title="Basic information"
        status={basicInfoStatus}
        defaultExpanded={!!client?.pepAnswer?.answer}
      >
        {(mappedPEPAnswer || []).map(answer => (
          <DocumentAccordionItemContainer key={answer.questionId}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6.5}>
                <Typography variant="body2">{answer.question}</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography variant="body2Medium">
                  Answer:{' '}
                  <Typography
                    variant="body2Medium"
                    component="span"
                    color={theme =>
                      answer.isNo
                        ? theme.palette.green[500]
                        : theme.palette.red[500]
                    }
                  >
                    {answer.isNo ? 'No' : 'Yes'}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            {answer.code === QuestionCode.Connection && !answer.isNo && (
              <PepAnswerDetails>
                <Grid container spacing={2}>
                  <Grid item xs={6.5}>
                    <Typography variant="caption">Position/Office</Typography>
                    <Typography variant="body2Medium">
                      {answer?.position || '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={5.5}>
                    <Typography variant="caption">Country</Typography>
                    <Typography variant="body2Medium">
                      {answer?.countryCode
                        ? t(
                            `location.country.${answer.countryCode as CountryCode3}`,
                          )
                        : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6.5}>
                    <Typography variant="caption">Start date</Typography>
                    <Typography variant="body2Medium">
                      {answer?.startDate
                        ? formatDate(answer?.startDate, dateFormat)
                        : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={5.5}>
                    <Typography variant="caption">End date</Typography>
                    <Typography variant="body2Medium">
                      {answer?.meWorking
                        ? 'Currently working in this role'
                        : answer?.endDate
                          ? formatDate(answer?.endDate, dateFormat)
                          : '-'}
                    </Typography>
                  </Grid>
                </Grid>
              </PepAnswerDetails>
            )}
            {answer.code === QuestionCode.Relatives && !answer.isNo && (
              <PepAnswerDetails>
                <Grid container spacing={2}>
                  <Grid item xs={6.5}>
                    <Typography variant="caption">Full name</Typography>
                    <Typography variant="body2Medium">
                      {answer?.fullName || '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={5.5}>
                    <Typography variant="caption">Relationship</Typography>
                    <Typography variant="body2Medium">
                      {answer?.relationship || '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6.5}>
                    <Typography variant="caption">Position/Office</Typography>
                    <Typography variant="body2Medium">
                      {answer?.position || '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={5.5}>
                    <Typography variant="caption">Country</Typography>
                    <Typography variant="body2Medium">
                      {answer?.countryCode
                        ? t(
                            `location.country.${answer.countryCode as CountryCode3}`,
                          )
                        : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6.5}>
                    <Typography variant="caption">Start date</Typography>
                    <Typography variant="body2Medium">
                      {answer?.startDate
                        ? formatDate(answer?.startDate, dateFormat)
                        : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={5.5}>
                    <Typography variant="caption">End date</Typography>
                    <Typography variant="body2Medium">
                      {answer?.isPersonWorking
                        ? 'Currently working in this role'
                        : answer?.endDate
                          ? formatDate(answer?.endDate, dateFormat)
                          : '-'}
                    </Typography>
                  </Grid>
                </Grid>
              </PepAnswerDetails>
            )}
          </DocumentAccordionItemContainer>
        ))}
        {(client?.pepAnswerEntries || []).length >= 3 &&
          client?.pepAnswer &&
          client.pepAnswer.status === PepAnswerStatus.InReview &&
          isActiveClient(client) && (
            <Can do="review" on="onboarding_application">
              <DocumentAccordionFooter>
                <BasicInfoApproveButton onClick={showConfirmBasicInfoModal}>
                  Approve
                </BasicInfoApproveButton>
              </DocumentAccordionFooter>
            </Can>
          )}
      </DocumentAccordion>
      <Modal
        {...getDataTestId('confirm-approve-basic-info-modal')}
        open={isConfirmingBasicInfo}
      >
        <Box>
          <FormCard
            title="Approve basic information"
            onClose={hideConfirmBasicInfoModal}
            center
          >
            <Typography variant="body2" mb={4}>
              Some of the answers are "Yes". Please, review carefully all the
              answers. Are you sure you want to approve basic information?
            </Typography>
            {approveBasicInfoError && (
              <Box mb={2}>
                <Alert
                  icon={<Icon name="alert" />}
                  color="error"
                  variant="standard"
                >
                  {getAPIErrorMessage(approveBasicInfoError)}
                </Alert>
              </Box>
            )}
            <FormAction>
              <LoadingButton
                variant="outlined"
                {...getDataTestId(
                  `confirm-approve-basic-info-modal-cancel-button`,
                )}
                onClick={hideConfirmBasicInfoModal}
                disabled={isPending}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                onClick={onApproveBasicInfo}
                {...getDataTestId(
                  `confirm-approve-basic-info-modal-approve-button`,
                )}
                disabled={isPending}
                isLoading={isPending}
              >
                Approve
              </LoadingButton>
            </FormAction>
          </FormCard>
        </Box>
      </Modal>
    </>
  );
}
