import { ClientAddressFields } from '@/constants';
import {
  Client,
  ClientStatus,
  CountryCode3,
  DocumentStatus,
  EmploymentStatus,
  Language,
  PepAnswerStatus,
  User,
} from '@/types';
import { Theme } from '@mui/material';
import { AppAbility } from './defineAbility';
import { i18nCountryTranslator } from './countryTranslations';

export function getDisplayedCitizenship(language: Language, client?: Client) {
  if (!client) return '';

  return [client.citizenship, client.citizenship2]
    .filter(i => !!i)
    .map(code => {
      return i18nCountryTranslator.getNationalityName(
        code as CountryCode3,
        language,
      );
    })
    .join('; ');
}

export function isActiveClient(client: Client) {
  return ![ClientStatus.Approved, ClientStatus.Rejected].includes(
    client.status,
  );
}

export function isInactiveClient(client: Client) {
  return [
    ClientStatus.Archived,
    ClientStatus.Approved,
    ClientStatus.Rejected,
  ].includes(client.status);
}

export function getBasicInfoStatus(client?: Client) {
  if (!client?.pepAnswer) return DocumentStatus.Pending;

  switch (client.pepAnswer.status) {
    case PepAnswerStatus.Approved:
      return DocumentStatus.Approved;
    case PepAnswerStatus.InReview:
      return DocumentStatus.InReview;
    case PepAnswerStatus.Rejected:
      return DocumentStatus.Rejected;
    default:
      return DocumentStatus.Pending;
  }
}

export function getDisplayedAddress(client: Client) {
  if (!client.addressDetailed) return '';

  const parts = Object.entries(client.addressDetailed)
    .filter(([key, value]) => !!value && ClientAddressFields.includes(key))
    .map(([, value]) => value);
  if (!parts.length) return '';

  let result = '';
  parts.forEach((item, index, array) => {
    if (!result) {
      result += item;
    } else if (array[index - 1] && /^\d+$/.test(array[index - 1])) {
      result += ` ${item}`;
    } else {
      result += `, ${item}`;
    }
  });

  return result;
}

export const getStatusIcon = (client: Client) => {
  if (client.status === ClientStatus.Approved) {
    return {
      icon: 'check',
      iconColor: (theme: Theme) =>
        theme.palette.status[ClientStatus.Approved]?.main,
    };
  } else if (client.status === ClientStatus.Rejected) {
    return {
      icon: 'block',
      iconColor: (theme: Theme) =>
        theme.palette.status[ClientStatus.Rejected]?.main,
    };
  } else if (client.eligible === false) {
    return {
      icon: 'pending-dots',
      iconColor: (theme: Theme) => theme.palette.warning.main,
    };
  } else {
    return {};
  }
};

export const hasMandateDocument = (client: Client) => {
  return (client.documents || []).some(
    doc =>
      /mandate/i.test('' + doc.docType) &&
      [DocumentStatus.InReview, DocumentStatus.Approved].includes(doc.status),
  );
};

export function shouldShowAssignButton({
  client,
  loggedInUser,
  ability,
}: {
  client?: Client;
  loggedInUser?: User;
  ability: AppAbility;
}) {
  if (!client || !loggedInUser) return false;

  return (
    ability.can('assign', 'onboarding_application') ||
    (loggedInUser?.id !== client.reviewBy &&
      ability.can('assign', 'onboarding_application_self')) ||
    (!!client.reviewBy && ability.can('unassign', 'onboarding_application')) ||
    (!!client.reviewBy &&
      client.reviewBy === loggedInUser.id &&
      ability.can('unassign', 'onboarding_application_self'))
  );
}

export function isUSCitizen(user?: Client) {
  if (!user || (!user.citizenship && !user.citizenship2)) return false;

  return [user.citizenship, user.citizenship2]
    .filter(i => !!i)
    .map(i => i?.toUpperCase())
    .includes('USA');
}

export function isUSTaxResidence(user?: Client) {
  if (!user || !user.taxResidence) return false;

  return [user.taxResidence]
    .filter(i => !!i)
    .map(i => i?.toUpperCase())
    .includes('USA');
}

export function isEmploymentInfoCollected(
  user: Pick<
    Client,
    'employmentStatus' | 'selfEmployedRiskSectorFlag' | 'employmentDesc'
  >,
) {
  if (!user.employmentStatus) return false;

  return (
    user.employmentStatus === EmploymentStatus.Employed ||
    user.selfEmployedRiskSectorFlag ||
    user.employmentDesc
  );
}
