import {
  AppConfigurations,
  DocumentSection,
  DocumentType,
  EmploymentStatus,
  SigningFormType,
} from '@/types';
import { DOCUMENT_TYPE_TEXT } from '../mapper';

export const defaultConfigs: AppConfigurations = {
  currency: 'USD',
  locale: 'en-US',
  isoCode: '',
  dateFormat: 'dd/MM/yyyy',
  name: '',
  requiredDocumentSections: [
    DocumentSection.Identity,
    DocumentSection.IdentityBack,
    DocumentSection.Selfie,
    DocumentSection.ProofOfEmployment,
    DocumentSection.DocumentSign,
  ],
  idDocumentTypes: [
    {
      value: DocumentType.Id,
      text: DOCUMENT_TYPE_TEXT[DocumentType.Id],
    },
    {
      value: DocumentType.Passport,
      text: DOCUMENT_TYPE_TEXT[DocumentType.Passport],
    },
  ],
  employmentQuestionByStatus: {
    [EmploymentStatus.SelfEmployed]: {
      question:
        'Does your business fall under any of the following categories?',
      description:
        "Real Estate, Agents, Motor Vehicles Agents, Gaming Houses, Pool Betting, National Lotteries On-Line Betting Games, Jewelry Business, Private Member's Clubs, Attorneys-at-Law, Accountants, or other persons performing the functions of an Accountant or other Independent Legal Professionals, Art Dealers, Trust and Company Service Providers.",
    },
  },
  requireLocalTin: false,
  flag: 'globe',
  formsOnEachAccountType: [SigningFormType.Authorization],
};
