import { Alert, Box, BoxProps, Typography } from '@mui/material';
import { useState } from 'react';
import { getFileExtension } from '@/utils';
import { FileSelectorContainer } from './FileSelector.styled';
import { UploadButton } from '../UploadButton';
import { Icon, IconCell } from '../../Icon';
import { useBoolean } from 'usehooks-ts';

type FileSelectorProps = {
  onSetFile: (value: File) => void;
  acceptExtensions: string[];
  maxBytes?: number;
  description?: string;
} & BoxProps;

export function FileSelector({
  description,
  acceptExtensions,
  maxBytes,
  onSetFile,
  ...props
}: FileSelectorProps) {
  const [fileError, setFileError] = useState('');
  const {
    value: isDraggingOn,
    setTrue: showDraggingOn,
    setFalse: hideDraggingOn,
  } = useBoolean(false);

  const onFileSelect = async (files: FileList | null) => {
    setFileError('');

    if (!files || !files.length) return;

    const file = files[0];
    const ext = getFileExtension(file);

    if (!acceptExtensions.length || !acceptExtensions.includes(ext)) {
      setFileError('Extension is not supported');
      return;
    }

    if (maxBytes && file.size > maxBytes) {
      setFileError('File size exceeded');
      return;
    }

    onSetFile(files[0]);
  };

  return (
    <FileSelectorContainer
      {...props}
      isHighlighted={isDraggingOn}
      onDragEnter={showDraggingOn}
      onDragLeave={hideDraggingOn}
      onDrop={hideDraggingOn}
    >
      <IconCell
        name="upload-alt"
        color={theme => theme.palette.primaries[100]}
        bgColor={theme => theme.palette.primaries[400]}
        size={79}
        iconSize={48}
      />
      <Typography variant="body2" textAlign="center" mt={3} mb={1}>
        <b>Select file</b> to upload or drag it here
      </Typography>
      {description && (
        <Typography variant="caption" mb={8} textAlign="center">
          {description}
        </Typography>
      )}
      {fileError && (
        <Box my={2}>
          <Alert icon={<Icon name="alert" />} color="error" variant="standard">
            {fileError}
          </Alert>
        </Box>
      )}
      <UploadButton onChange={onFileSelect} accept={acceptExtensions.join(',')}>
        Upload
      </UploadButton>
    </FileSelectorContainer>
  );
}
