import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  isUSCitizen,
  isUSTaxResidence,
  standardizeDocumentsToSign,
} from '@/utils';
import { AccountTypeDetails, Client, SigningFormType, Tenant } from '@/types';
import { appConfigs } from '@/constants';

export const selectDocumentsState = createSelector(
  (state: RootState) => state.documents,
  data => ({ ...data }),
);

export const selectSigningDocuments = ({
  client,
  clientAccountTypes,
  tenant,
}: {
  client: Client;
  clientAccountTypes: AccountTypeDetails[];
  tenant: Tenant;
}) =>
  createSelector(
    (state: RootState) => state.documents,
    data =>
      standardizeDocumentsToSign(
        data.signingForms,
        clientAccountTypes || [],
        tenant ? appConfigs[tenant].formsOnEachAccountType : [],
      ).filter(form => {
        if (form?.docType === SigningFormType.W9) {
          return (
            isUSCitizen(client) || isUSTaxResidence(client) || client?.usIndicia
          );
        }
        return true;
      }),
  );

export const selectLocalDocuments = createSelector(
  (state: RootState) => state.documents,
  data => data.documentResource,
);

export const selectLocalDocument = (uri?: string) =>
  createSelector(
    (state: RootState) => state.documents.documentResource,
    data => (uri ? data[uri] : null),
  );
