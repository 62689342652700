import { Language, TranslationKey } from '@/types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function useTranslate() {
  const { t, i18n, ...translation } = useTranslation();

  const translate = useCallback(
    (translationId: TranslationKey, values?: Record<string, string>) => {
      return t(translationId, values);
    },
    [t],
  );

  const changeLanguage = useCallback(
    (language: Language) => {
      return i18n.changeLanguage(language);
    },
    [i18n],
  );

  return { t: translate, changeLanguage, i18n, ...translation };
}
