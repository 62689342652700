import { Client, Note, Pagination } from '@/types';

export const DEFAULT_PAGINATION: Pagination = {
  page: 0,
  size: 10,
  total: 1,
};

export const REQUEST = {
  USER: {
    ROOT: '/users',
    TENANTS: '/userTenant',
    INFO: '/userInfo',
    UPDATE_USER: (id: string) => `/users/${id}`,
    ENABLE_DISABLE: (id: string) => `/users/${id}`,
    UPDATE_USER_ROLES: (id: string) => `/users/${id}/roles`,
  },
  ROLES: {
    ROOT: '/roles',
    ROLE_DETAIL: (id: string) => `/roles/${id}`,
    PERMISSIONS: '/permissions',
    UPDATE_ROLE_PERMISSIONS: (id: string) => `/roles/${id}/permissions`,
  },
  CLIENT: {
    ROOT: '/clients',
    INFO: (id: Client['id']) => `/clients/${id}`,
    REQUIRED_DOCUMENTS: (id: Client['id']) => `/clients/${id}/required-doc`,
    PEP: (id: Client['id']) => `/clients/${id}/pep`,
    PEP_CHECK: (id: Client['id']) => `/clients/${id}/pep_check`,
    PEP_DETAILS: (id: Client['id']) => `/clients/${id}/pep-info`,
    APPROVE_PEP_CHECK: (clientId: Client['id'], pepRecordId: string) =>
      `/clients/${clientId}/pep-info/${pepRecordId}`,
    REVIEW_DOCUMENT: (id: Client['id']) => `/clients/${id}/document_review`,
    ASSIGN: (id: Client['id']) => `/clients/${id}/assignee`,
    OPERATION_REVIEW: (id: Client['id']) => `/clients/${id}/operation_review`,
    RISK_LEVEL: (id: Client['id']) => `/clients/${id}/risk-level`,
    APPROVE_BASIC_INFO: (clientId: Client['id'], answerId: string | number) =>
      `/clients/${clientId}/pep_answers/${answerId}/approve`,
    CREATE_ICB_PROFILE: (id: Client['id']) => `/clients/${id}/icb-profile`,
    CREATE_BANK_PROFILE: (id: Client['id']) => `/clients/${id}/bank-profile`,
    REQUEST_DOCUMENT: (id: Client['id']) => `/clients/${id}/doc-request`,
    BANK_ACCOUNTS: (id: Client['id']) => `/clients/${id}/bankAccounts`,
    COMMENTS: (id: Client['id']) => `/clients/${id}/comments`,
    COMMENT_DETAILS: (id: Client['id'], noteId: Note['id']) =>
      `/clients/${id}/comments/${noteId}`,
    DOCUMENTS: (id: Client['id']) => `/clients/${id}/documents`,
    SIMILAR_ACCOUNTS: '/phoenix/search-similar',
    REACTIVATE: (id: Client['id'], operationId: string) =>
      `/clients/${id}/reactivate/${operationId}`,
    EXPORT: `/clients/export`,
  },
  DOCUMENT: {
    ROOT: '/documents',
    INFO: (id: string | number) => `/documents/${id}`,
    SIGNING_FORMS: (id: string | number) => `/clients/${id}/docSignForms`,
    PRESIGNED_URL: (uri: string) => `/documents/presignedUrl?uri=${uri}`,
    REQUEST: '/documents/requestTemplate',
    DOWNLOAD_BY_S3: '/documents/downloadS3Object',
  },
  REVIEWERS: {
    ROOT: '/reviewers',
  },
  AUDIT: {
    ROOT: '/audit',
    CLIENT: '/audit/client',
  },
  STATS: {
    SUMMARY: '/stats/summary',
    MY_TASKS: '/stats/my-tasks',
    ACCOUNT_OPENING: '/stats/accountOpening',
  },
  PICKLIST: {
    RISK_TYPES: '/dictionary?type=RISK_VALUES',
  },
  BRANCHES: `/territories/branches`,
  JOB: {
    ROOT: '/scheduleJobs',
    INFO: (id: string) => `/scheduleJobs/${id}`,
    RUN: (id: string) => `/scheduleJobs/${id}/run`,
  },
  FEATURE: {
    ROOT: 'features',
  },
  DICTIONARY: {
    ROOT: 'dictionary',
  },
};
