import { useEffect, useRef } from 'react';
import { renderAsync } from 'docx-preview';
import { useQuery } from '@tanstack/react-query';
import { getAPIErrorMessage } from '@/utils';
import { Loader, Icon } from '@/components/Icon';
import { ViewerCenterSlot } from '../File.styled';
import { Alert } from '@mui/material';
import { Container } from './DocRenderer.styled';

interface DocRendererProps {
  file: string;
}

export function DocRenderer({ file }: DocRendererProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    data: blob,
    error,
    isLoading,
  } = useQuery({
    queryKey: [file],
    queryFn: async () => {
      const data = await fetch(file);
      const blob = await data.blob();
      return blob;
    },
  });

  useEffect(() => {
    const renderDocument = async () => {
      if (containerRef.current) {
        await renderAsync(blob, containerRef.current);
      }
    };

    renderDocument();
  }, [blob, file]);

  if (isLoading) {
    return (
      <ViewerCenterSlot>
        <Loader />
      </ViewerCenterSlot>
    );
  }

  if (error) {
    return (
      <ViewerCenterSlot>
        <Alert icon={<Icon name="alert" />} color="error" variant="standard">
          {getAPIErrorMessage(error)}
        </Alert>
      </ViewerCenterSlot>
    );
  }

  return <Container ref={containerRef} />;
}
