export const VITE_PROD = import.meta.env.PROD;
export const VITE_MODE = import.meta.env.MODE;
export const VITE_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;
export const VITE_RELEASE_TIME = import.meta.env.VITE_RELEASE_TIME;
export const VITE_BASE_API_URL = import.meta.env.VITE_BASE_API_URL;
export const VITE_COGNITO_USER_POOL_ID = import.meta.env
  .VITE_COGNITO_USER_POOL_ID;
export const VITE_COGNITO_CLIENT_ID = import.meta.env.VITE_COGNITO_CLIENT_ID;
export const VITE_COGNITO_EXTERNAL_PROVIDER_DOMAIN = import.meta.env
  .VITE_COGNITO_EXTERNAL_PROVIDER_DOMAIN;
export const VITE_COGNITO_EXTERNAL_PROVIDER_NAME = import.meta.env
  .VITE_COGNITO_EXTERNAL_PROVIDER_NAME;
export const VITE_TENANT = import.meta.env.VITE_TENANT;
export const VITE_BUGFENDER_APP_KEY = import.meta.env.VITE_BUGFENDER_APP_KEY;