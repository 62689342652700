import {
  Comment,
  FileCommentInput,
  FileContainer,
  Form,
} from '../Modals.styled';
import { FormAction } from '@/components/Layout';
import { DrawerProps } from '../Modals.interface';
import { Alert, Box, Button, Typography } from '@mui/material';
import {
  Client,
  Document,
  DocumentSection,
  DocumentStatus,
  ModalName,
  ReviewDocumentForm,
} from '@/types';
import { Icon } from '@/components/Icon';
import {
  getAPIErrorMessage,
  getDataTestId,
  getDisplayField,
  getTimeDuration,
  isExcelContentType,
  isUnreviewableDocument,
} from '@/utils';
import {
  ChangeEventHandler,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { DOCUMENT_TYPE_TEXT } from '@/constants';
import { FileFrame } from '@/components/File';
import { useDispatch, useModal } from '@/hooks';
import { showToast } from '@/redux';
import { Status } from '@/components/DataDisplay';
import { useMutation } from '@tanstack/react-query';
import { reviewDocument } from '@/api';
import { Can } from '@/components/Can';
import { BasicDrawer } from '../BasicDrawer';
import { DocumentDrawer } from '../DocumentDrawer.styled';
import { ReviewButton } from './ReviewButton';
import { useClientDetails } from '@/hooks';

type DocumentReviewDrawerProps = {
  client?: Client;
  document?: Document;
} & DrawerProps;

export function DocumentReviewDrawer({
  client,
  document: documentData,
  ...props
}: DocumentReviewDrawerProps) {
  const [payload, setPayload] = useState<Partial<ReviewDocumentForm>>({});
  const dispatch = useDispatch();
  const { hide, hideAndRefresh } = useModal({ name: ModalName.ReviewDocument });
  const { mutate, isSuccess, isPending, error, reset } = useMutation({
    mutationFn: reviewDocument,
  });
  const clientData = useClientDetails(client?.id);
  const isReviewableDocument =
    documentData &&
    clientData &&
    !isUnreviewableDocument(documentData, clientData);
  const isRejectedDocument = documentData?.status === DocumentStatus.Rejected;
  const isApprovedDocument = documentData?.status === DocumentStatus.Approved;

  useEffect(() => {
    if (props.open) {
      setPayload({
        clientId: clientData?.id,
        documentId: documentData?.id,
        message: '',
      });
    }
  }, [props.open, documentData, clientData]);

  const onSetPayload = (value: Partial<ReviewDocumentForm>) => {
    setPayload(prev => ({
      ...prev,
      ...value,
    }));
  };

  useEffect(() => {
    if (payload.status) {
      reset();
      mutate(payload as ReviewDocumentForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  useEffect(() => {
    if (isSuccess && payload.status) {
      if (payload.status === DocumentStatus.Approved) {
        dispatch(
          showToast({
            message: 'Document approved successfully!',
            severity: 'success',
          }),
        );
      } else if (payload.status === DocumentStatus.Rejected) {
        dispatch(
          showToast({
            message: 'Document were rejected. Request new.',
            severity: 'error',
          }),
        );
      }
      onSetPayload({
        status: undefined,
      });
      hideAndRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const onMessageChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
    onSetPayload({
      message: event.target.value,
    });
  };

  const onApprove = () => {
    onSetPayload({
      status: DocumentStatus.Approved,
    });
  };

  const onReject = () => {
    onSetPayload({
      status: DocumentStatus.Rejected,
    });
  };

  if (!document) {
    return (
      <BasicDrawer
        {...getDataTestId('document-review-drawer')}
        {...props}
        title="Document review"
        onClose={hide}
      />
    );
  }

  const renderMessage = () => {
    if (documentData?.status === DocumentStatus.AboutToExpire) {
      return (
        <Typography variant="body2" textAlign="center">
          The document is about to expire in{' '}
          <Bold>
            {getTimeDuration(new Date().getTime(), documentData?.expiryDate)}
          </Bold>
          ! Please request the most recent version of the{' '}
          {DOCUMENT_TYPE_TEXT[documentData?.type]} from{' '}
          <Bold>
            {documentData?.owner
              ? getDisplayField(documentData?.owner)
              : 'the client'}
          </Bold>
          .
        </Typography>
      );
    } else if (!isReviewableDocument) {
      return null;
    }

    return (
      <Typography variant="body2" textAlign="center">
        Please review this{' '}
        {documentData?.docType ? (
          <Bold>
            {documentData.type === DocumentSection.DocumentSign
              ? documentData.formName
              : DOCUMENT_TYPE_TEXT[documentData?.docType as string] ||
                documentData?.docType}
          </Bold>
        ) : (
          'document'
        )}{' '}
        and decide whether to approve or reject it based on its content,
        accuracy, and compliance
      </Typography>
    );
  };

  const renderAction = () => {
    if (!isReviewableDocument) {
      return (
        <FormAction align="stretch">
          <CancelButton onClick={hide} />
        </FormAction>
      );
    }

    return (
      <FormAction align="stretch">
        <Can do="review" on="document">
          {(isRejectedDocument || isApprovedDocument) && (
            <CancelButton onClick={hide} />
          )}
          {!isRejectedDocument && (
            <ReviewButton
              text="Reject"
              type="reject"
              onClick={onReject}
              disabled={isPending}
              variant={isApprovedDocument ? 'contained' : 'outlined'}
              color={!isApprovedDocument ? 'inherit' : 'error'}
              isLoading={
                isPending && payload.status === DocumentStatus.Rejected
              }
            />
          )}
          {!isApprovedDocument && (
            <ReviewButton
              text="Approve"
              type="approve"
              onClick={onApprove}
              disabled={isPending}
              isLoading={
                isPending && payload.status === DocumentStatus.Approved
              }
            />
          )}
        </Can>
      </FormAction>
    );
  };

  return (
    <DocumentDrawer
      {...getDataTestId('document-review-drawer')}
      {...props}
      onClose={hide}
      title={
        <>
          <span>
            {documentData?.formName ||
              DOCUMENT_TYPE_TEXT[String(documentData?.docType)] ||
              documentData?.docType ||
              DOCUMENT_TYPE_TEXT[String(documentData?.type)] ||
              'Document review'}
          </span>
          {!isReviewableDocument && (
            <>
              {' '}
              <Status value={String(documentData?.status)} display="inline" />
            </>
          )}
        </>
      }
    >
      <Form>
        {renderMessage()}
        <FileContainer>
          {documentData && (
            <FileFrame
              document={documentData}
              disabledCache={isExcelContentType(documentData.contentType)}
            />
          )}
        </FileContainer>
        {isReviewableDocument ? (
          <Can do="review" on="document">
            <FileCommentInput
              placeholder="Write your message"
              onChange={onMessageChange}
              value={payload.message}
              disabled={isPending}
            />
          </Can>
        ) : documentData?.reviewMessage ? (
          <Comment>{documentData?.reviewMessage}</Comment>
        ) : null}
        {error && (
          <Box mb={2}>
            <Alert
              icon={<Icon name="alert" />}
              color="error"
              variant="standard"
            >
              {getAPIErrorMessage(error)}
            </Alert>
          </Box>
        )}
        {renderAction()}
      </Form>
    </DocumentDrawer>
  );
}

const Bold = ({ children }: PropsWithChildren) => (
  <Typography variant="body2Bold" component="span">
    {children}
  </Typography>
);

const CancelButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    variant="outlined"
    onClick={onClick}
    {...getDataTestId(`review-document-action-cancel-button`)}
  >
    Cancel
  </Button>
);
