import { Box, styled } from '@mui/material';

export const Container = styled(Box)(() => ({
  '.docx-wrapper': {
    backgroundColor: 'transparent',
    display: 'inline-block',

    '.docx': {
      boxShadow: 'none',
      border: '1px solid #EFEFEF',
    },
  },
}));

export const DocFileViewerContainer = styled(Box)(() => ({
  '.docx-wrapper': {
    display: 'flex',
  },
}));
