import { ReactNode, useEffect, useRef, useState } from 'react';
import { Document as PDFDocument, Page, PasswordResponses } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import type { PDFDocumentProxy } from 'pdfjs-dist';
import { Alert } from '@mui/material';
import { Icon, Loader } from '../Icon';
import { PDFContainer } from './File.styled';

type PDFRendererProps = {
  file: string | File;
  pageLimit?: number;
  renderTextLayer?: boolean;
  loader?: ReactNode;
  onCancelPassword?: () => void;
};

type OnPasswordCallback = (password: string) => void;

export function PDFRenderer({
  file,
  renderTextLayer,
  pageLimit = 0,
  loader,
  onCancelPassword,
}: PDFRendererProps) {
  const [numPages, setNumPages] = useState<number>();
  const [containerWidth, setContainerWidth] = useState<number>();

  const containerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const { width } = containerRef.current.getBoundingClientRect();
      const { paddingLeft, paddingRight } = getComputedStyle(
        containerRef.current,
      );
      setContainerWidth(width - parseInt(paddingLeft) - parseInt(paddingRight));
    }
  }, [containerRef]);

  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: PDFDocumentProxy): void {
    setNumPages(pageLimit ? Math.min(nextNumPages, pageLimit) : nextNumPages);
  }

  function onPassword(callback: OnPasswordCallback, reason: number) {
    function callbackProxy(password: string | null) {
      // Cancel button handler
      if (password === null) {
        onCancelPassword && onCancelPassword();
        return;
      }

      callback(password);
    }

    switch (reason) {
      case PasswordResponses.NEED_PASSWORD: {
        const password = prompt('Enter the password to open this PDF file.');
        callbackProxy(password);
        break;
      }
      case PasswordResponses.INCORRECT_PASSWORD: {
        const password = prompt('Invalid password. Please try again.');
        callbackProxy(password);
        break;
      }
      default:
    }
  }

  return (
    <PDFContainer ref={containerRef} className="PDFContainer">
      <PDFDocument
        file={file}
        externalLinkTarget="_blank"
        onLoadSuccess={onDocumentLoadSuccess}
        onPassword={onPassword}
        loading={
          loader || (
            <Loader
              justifyContent="center"
              iconProps={{
                size: 32,
                color: theme => theme.palette.grey[700],
              }}
            />
          )
        }
        error={
          <Alert icon={<Icon name="alert" />} color="error" variant="standard">
            Failed to load PDF file.
          </Alert>
        }
      >
        {Array.from(new Array(numPages), (_, index) => (
          <Page
            key={`page-${index}`}
            renderTextLayer={renderTextLayer}
            renderAnnotationLayer={renderTextLayer}
            pageNumber={index + 1}
            width={containerWidth}
            loading={null}
          />
        ))}
      </PDFDocument>
    </PDFContainer>
  );
}
