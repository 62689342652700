import { MediaPlayer, VideoPlayerContainer } from './VideoPlayer.styled';
type IVideoPlayerProps = {
  src: string;
};

export const VideoPlayer = ({ src }: IVideoPlayerProps) => {
  return (
    <VideoPlayerContainer>
      <MediaPlayer muted url={src} controls />
    </VideoPlayerContainer>
  );
};
