import { AppConfigurations, SigningFormType } from '@/types';
import { defaultConfigs } from './default';

export const surinameConfigs: AppConfigurations = {
  ...defaultConfigs,
  locale: 'en-GB',
  isoCode: 'SUR',
  currency: 'SRD',
  name: 'Suriname',
  flag: 'flag-suriname',
  formsOnEachAccountType: [
    SigningFormType.Authorization,
    SigningFormType.AML4,
    SigningFormType.SIGNATURE,
  ],
};
