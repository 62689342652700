import { ClientStatus } from './client';
import { DocumentStatus } from './document';

export type Pagination = {
  page: number;
  size: number;
  total: number;
};

export type ApiFilter = {
  pagination?: Pagination;
  query?: string;
  sort?: string;
  filter?: Record<string, string[]>;
  dueFrom?: string;
  dueTo?: string;
  from?: string;
  to?: string;
  excludeFields?: string[];
  fields?: string[];
  dateFrom?: string;
  dateTo?: string;
};

export type DataList<T> = {
  data: T[];
} & Pagination;

export enum GenericStatus {
  Verified = 'verified',
  Unverified = 'unverified',
}

export type CommonStatus = DocumentStatus | ClientStatus;

export enum MessageTemplateType {
  RequestDocument = 'request_document',
}

export enum SignInStep {
  Login = 'LOG_IN',
  ForgotPassword = 'FORGOT_PASSWORD',
  AmplifyNewPasswordRequired = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
}

export enum ResetPasswordStep {
  AmplifyDone = 'DONE',
  AmplifyConfirmCode = 'CONFIRM_RESET_PASSWORD_WITH_CODE',
}

export enum Environment {
  Development = 'DEVELOPMENT',
  Staging = 'STAGING',
  Production = 'PRODUCTION',
}

export enum Tenant {
  Anguilla = 'aia',
  Barbados = 'brb',
  BVI = 'bvi',
  Cayman = 'cym',
  Dominica = 'dma',
  Ghana = 'gha',
  Grenada = 'grd',
  Guyana = 'guy',
  SintMaarten = 'sxm',
  StKitts = 'kna',
  StLucia = 'lca',
  StVincent = 'vct',
  Suriname = 'sur',
  Trinidad = 'tto',
}

export type InputValueHelperRule = {
  text: string;
  isMatched: (value?: string | number) => boolean;
};

export type DotPrefix<T extends string> = T extends '' ? '' : `.${T}`;

export type DotNestedKeys<T> = (
  T extends object
    ? {
        [K in keyof T]: `${K & string}${DotPrefix<DotNestedKeys<T[K]>>}`;
      }[keyof T]
    : ''
) extends infer D
  ? Extract<D, string>
  : never;

export type CountryCode3 =
  | 'AND'
  | 'ARE'
  | 'AFG'
  | 'ATG'
  | 'AIA'
  | 'ALB'
  | 'ARM'
  | 'AGO'
  | 'ATA'
  | 'ARG'
  | 'ASM'
  | 'AUT'
  | 'AUS'
  | 'ABW'
  | 'ALA'
  | 'AZE'
  | 'BIH'
  | 'BRB'
  | 'BGD'
  | 'BEL'
  | 'BFA'
  | 'BGR'
  | 'BHR'
  | 'BDI'
  | 'BEN'
  | 'BLM'
  | 'BMU'
  | 'BRN'
  | 'BOL'
  | 'BES'
  | 'BRA'
  | 'BHS'
  | 'BTN'
  | 'BVT'
  | 'BWA'
  | 'BLR'
  | 'BLZ'
  | 'CAN'
  | 'CCK'
  | 'COD'
  | 'CAF'
  | 'COG'
  | 'CHE'
  | 'CIV'
  | 'COK'
  | 'CHL'
  | 'CMR'
  | 'CHN'
  | 'COL'
  | 'CRI'
  | 'CUB'
  | 'CPV'
  | 'CUW'
  | 'CXR'
  | 'CYP'
  | 'CZE'
  | 'DEU'
  | 'DJI'
  | 'DNK'
  | 'DMA'
  | 'DOM'
  | 'DZA'
  | 'ECU'
  | 'EST'
  | 'EGY'
  | 'ESH'
  | 'ERI'
  | 'ESP'
  | 'ETH'
  | 'FIN'
  | 'FJI'
  | 'FLK'
  | 'FSM'
  | 'FRO'
  | 'FRA'
  | 'GAB'
  | 'GBR'
  | 'GRD'
  | 'GEO'
  | 'GUF'
  | 'GGY'
  | 'GHA'
  | 'GIB'
  | 'GRL'
  | 'GMB'
  | 'GIN'
  | 'GLP'
  | 'GNQ'
  | 'GRC'
  | 'SGS'
  | 'GTM'
  | 'GUM'
  | 'GNB'
  | 'GUY'
  | 'HKG'
  | 'HMD'
  | 'HND'
  | 'HRV'
  | 'HTI'
  | 'HUN'
  | 'IDN'
  | 'IRL'
  | 'ISR'
  | 'IMN'
  | 'IND'
  | 'IOT'
  | 'IRQ'
  | 'IRN'
  | 'ISL'
  | 'ITA'
  | 'JEY'
  | 'JAM'
  | 'JOR'
  | 'JPN'
  | 'KEN'
  | 'KGZ'
  | 'KHM'
  | 'KIR'
  | 'COM'
  | 'KNA'
  | 'PRK'
  | 'KOR'
  | 'KWT'
  | 'CYM'
  | 'KAZ'
  | 'LAO'
  | 'LBN'
  | 'LCA'
  | 'LIE'
  | 'LKA'
  | 'LBR'
  | 'LSO'
  | 'LTU'
  | 'LUX'
  | 'LVA'
  | 'LBY'
  | 'MAR'
  | 'MCO'
  | 'MDA'
  | 'MNE'
  | 'MAF'
  | 'MDG'
  | 'MHL'
  | 'MKD'
  | 'MLI'
  | 'MMR'
  | 'MNG'
  | 'MAC'
  | 'MNP'
  | 'MTQ'
  | 'MRT'
  | 'MSR'
  | 'MLT'
  | 'MUS'
  | 'MDV'
  | 'MWI'
  | 'MEX'
  | 'MYS'
  | 'MOZ'
  | 'NAM'
  | 'NCL'
  | 'NER'
  | 'NFK'
  | 'NGA'
  | 'NIC'
  | 'NLD'
  | 'NOR'
  | 'NPL'
  | 'NRU'
  | 'NIU'
  | 'NZL'
  | 'OMN'
  | 'PAN'
  | 'PER'
  | 'PYF'
  | 'PNG'
  | 'PHL'
  | 'PAK'
  | 'POL'
  | 'SPM'
  | 'PCN'
  | 'PRI'
  | 'PSE'
  | 'PRT'
  | 'PLW'
  | 'PRY'
  | 'QAT'
  | 'REU'
  | 'ROU'
  | 'SRB'
  | 'RUS'
  | 'RWA'
  | 'SAU'
  | 'SLB'
  | 'SYC'
  | 'SDN'
  | 'SWE'
  | 'SGP'
  | 'SHN'
  | 'SVN'
  | 'SJM'
  | 'SVK'
  | 'SLE'
  | 'SMR'
  | 'SEN'
  | 'SOM'
  | 'SUR'
  | 'SSD'
  | 'STP'
  | 'SLV'
  | 'SXM'
  | 'SYR'
  | 'SWZ'
  | 'TCA'
  | 'TCD'
  | 'ATF'
  | 'TGO'
  | 'THA'
  | 'TJK'
  | 'TKL'
  | 'TLS'
  | 'TKM'
  | 'TUN'
  | 'TON'
  | 'TUR'
  | 'TTO'
  | 'TUV'
  | 'TWN'
  | 'TZA'
  | 'UKR'
  | 'UGA'
  | 'UMI'
  | 'USA'
  | 'URY'
  | 'UZB'
  | 'VAT'
  | 'VCT'
  | 'VEN'
  | 'VGB'
  | 'VIR'
  | 'VNM'
  | 'VUT'
  | 'WLF'
  | 'WSM'
  | 'XKX'
  | 'YEM'
  | 'MYT'
  | 'ZAF'
  | 'ZMB'
  | 'ZWE';
