import nationalities from 'i18n-nationality';
import nationalityEn from 'i18n-nationality/langs/en.json';
import nationalityNl from 'i18n-nationality/langs/nl.json';
import i18nCountries from 'i18n-iso-countries';
import countriesEn from 'i18n-iso-countries/langs/en.json';
import countriesNl from 'i18n-iso-countries/langs/nl.json';
import { CountryCode3, Language } from '@/types';
import { CUSTOM_COUNTRIES, RAW_COUNTRIES } from '@/constants';

nationalities.registerLocale(nationalityEn);
nationalities.registerLocale(nationalityNl);
i18nCountries.registerLocale(countriesEn);
i18nCountries.registerLocale(countriesNl);

type CountryTranslator = {
  getCountryName: (code3: string, language: Language) => string | undefined;
  getNationalityName: (code3: string, language: Language) => string | undefined;
};

export const i18nCountryTranslator: CountryTranslator = {
  getCountryName: (code3: string, language: Language) =>
    i18nCountries.getName(code3, language),
  getNationalityName: (code3: string, language: Language) =>
    nationalities.getName(code3, language),
};

export const getCountryTranslations = (
  language: Language,
  translator: CountryTranslator = i18nCountryTranslator,
) => {
  const translations = {
    country: {} as Record<CountryCode3, string>,
    nationality: {} as Record<CountryCode3, string>,
  };

  RAW_COUNTRIES.forEach(country => {
    const code3 = country.code3 as CountryCode3;

    translations.country[code3] =
      translator.getCountryName(code3, language) || country.name;
    translations.nationality[code3] =
      translator.getNationalityName(code3, language) ||
      translator.getNationalityName(code3, Language.English) ||
      country.name;
  });

  CUSTOM_COUNTRIES.forEach(country => {
    if (country.code3) {
      const code3 = country.code3 as CountryCode3;
      translations.country[code3] = country.name;
      translations.nationality[code3] = country.name;
    }
  });

  return translations;
};
