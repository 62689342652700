import { FormAction, FormCard, Icon, LoadingButton } from '@/components';
import { getDataTestId } from '@/utils';
import { Box, Button, ButtonProps, Modal, Typography } from '@mui/material';
import { useBoolean } from 'usehooks-ts';

type ReviewButtonProps = {
  type: 'approve' | 'reject';
  text: string;
  onClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
} & Omit<ButtonProps, 'type' | 'onClick'>;

export function ReviewButton({
  type,
  text,
  onClick,
  disabled,
  isLoading,
  ...props
}: ReviewButtonProps) {
  const {
    value: isConfirming,
    setTrue: showConfirmModal,
    setFalse: hideConfirmModal,
  } = useBoolean(false);

  const handleOnClick = () => {
    onClick();
    hideConfirmModal();
  };

  return (
    <>
      <LoadingButton
        startIcon={
          type === 'approve' ? <Icon name="check" /> : <Icon name="file-x" />
        }
        onClick={showConfirmModal}
        isLoading={isLoading}
        disabled={disabled}
        {...getDataTestId(`review-document-action-${type}-button`)}
        {...props}
      >
        {text}
      </LoadingButton>
      <Modal open={isConfirming} {...getDataTestId(`${type}-document-modal`)}>
        <Box>
          <FormCard
            title={`${text} document`}
            onClose={hideConfirmModal}
            center
          >
            <Typography variant="body2" mb={4}>
              Are you sure you want to {type} this document?
            </Typography>
            <FormAction>
              <Button
                variant="outlined"
                onClick={hideConfirmModal}
                {...getDataTestId(
                  `confirm-${type}-document-modal-cancel-button`,
                )}
              >
                Cancel
              </Button>
              <Button
                color={type === 'approve' ? 'primary' : 'error'}
                onClick={handleOnClick}
                {...getDataTestId(
                  `confirm-${type}-document-modal-${type}-button`,
                )}
              >
                {text}
              </Button>
            </FormAction>
          </FormCard>
        </Box>
      </Modal>
    </>
  );
}
