import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { ENGLISH } from './english';
import { DUTCH } from './dutch';
import { Language } from '@/types';

const resources = {
  [Language.English]: {
    translation: ENGLISH,
  },
  [Language.Dutch]: {
    translation: DUTCH,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: Language.English,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
