import _axios, { AxiosError } from 'axios';
import { signOut } from 'aws-amplify/auth';
import { Bugfender } from '@bugfender/sdk';

import { VITE_BASE_API_URL } from '@/constants';

export const axios = _axios.create({
  baseURL: VITE_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  // .. other options
});

axios.interceptors.response.use(
  response => response,
  function (error: AxiosError) {
    if (error.response?.status === 401) {
      Bugfender.error('session-timeout', error.response.data);
      signOut();
      axios.defaults.headers.common['x-tenant'] = undefined;
    }

    if (error.response?.status === 403) {
      Bugfender.error('request-error', error.response.data);
      return Promise.reject(new Error('Access denied'));
    }
    Bugfender.error('request-error', error);
    return Promise.reject(error);
  },
);
