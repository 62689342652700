import { CountryISO, IconName, Language, Option } from '@/types';
import { countries, getCountryData, TCountryCode } from 'countries-list';
import nationalities from 'i18n-nationality';
import nationalityEn from 'i18n-nationality/langs/en.json';
import nationalityNl from 'i18n-nationality/langs/nl.json';
import i18nCountries from 'i18n-iso-countries';
import countriesEn from 'i18n-iso-countries/langs/en.json';
import countriesNl from 'i18n-iso-countries/langs/nl.json';
nationalities.registerLocale(nationalityEn);
nationalities.registerLocale(nationalityNl);
i18nCountries.registerLocale(countriesEn);
i18nCountries.registerLocale(countriesNl);

const EXCLUDED_COUNTRIES = ['antarctica'];

export const POPULAR_COUNTRIES: string[] = [
  CountryISO.Cayman,
  CountryISO.US,
  CountryISO.UK,
  CountryISO.China,
  CountryISO.France,
  CountryISO.India,
];

export const POPULAR_NATIONALITIES: string[] = [
  CountryISO.Cayman,
  CountryISO.US,
  CountryISO.UK,
  CountryISO.China,
  CountryISO.France,
  CountryISO.India,
];

export const CUSTOM_COUNTRIES = [
  {
    name: 'Anguilla',
    native: 'Anguilla',
    code2: 'AI',
    code3: 'AIA',
  },
];

export const CURRENCY_SIGN: Record<string, string> = {
  USD: 'USD',
  usd: 'USD',
  KYD: 'KYD',
  kyd: 'KYD',
  ANG: 'ANG',
  ang: 'ANG',
  BBD: 'BBD',
  bbd: 'BBD',
  XCD: 'XCD',
  xcd: 'XCD',
  GHS: 'GHS',
  ghs: 'GHS',
  GYD: 'GYD',
  gyd: 'GYD',
  SRD: 'SRD',
  srd: 'SRD',
  TTD: 'TTD',
  ttd: 'TTD',
};

export const LANGUAGES: Option[] = [
  {
    text: 'English',
    value: Language.English,
    icon: 'flag-united-kingdom',
  },
  {
    text: 'Dutch',
    value: Language.Dutch,
    icon: 'flag-netherlands',
  },
];
export const getStandardizeCountryName = (name: string) => {
  switch (name) {
    case 'Aland':
      return 'Aland Islands';
    case 'Cocos (Keeling) Islands':
      return 'Cocos Islands';
    case 'U.S. Virgin Islands':
      return 'Virgin Islands';
    case 'Myanmar (Burma)':
      return 'Myanmar';
    case 'Anguilla':
      return 'Anguilla, British';
    default:
      return name;
  }
};
const modifiedCountries = Object.entries(countries)
  .filter(([, item]) => !EXCLUDED_COUNTRIES.includes(item.name.toLowerCase()))
  .map(([code, value]) => ({
    ...value,
    code2: code,
    code3: getCountryData(code as TCountryCode).iso3,
  }))
  .sort((a, b) => a.name.localeCompare(b.name));

export const RAW_COUNTRIES = modifiedCountries;

export const PHONE_CODES: Option[] = modifiedCountries.map(value => {
  return {
    text: '+' + value.phone[0],
    value: '+' + value.phone[0],
  };
});
export const COUNTRIES: Option[] = modifiedCountries
  .map(value => {
    const countryFlag = value.name.toLowerCase().replace(/\s+/g, '-');
    return {
      icon: `flag-${countryFlag}` as IconName,
      text: value.name,
      value: value.code3,
    };
  })
  .sort((a, b) => (a.text || '').localeCompare(b.text || ''));

export const NATIONALITIES: Option[] = modifiedCountries
  .filter(value => !!nationalities.getName(value.code3, 'en'))
  .concat({
    name: 'Anguilla',
    native: 'Anguilla',
    phone: [1264],
    continent: 'NA',
    capital: 'The Valley',
    currency: ['XCD'],
    languages: ['en'],
    code2: 'AI',
    code3: 'AIA',
  })
  .map(value => {
    const countryText = getStandardizeCountryName(
      nationalities.getName(value.code3, 'en') || value.name,
    );
    const countryFlag = value.name.toLowerCase().replace(/\s+/g, '-');

    return {
      icon: `flag-${countryFlag}` as IconName,
      text: countryText,
      value: value.code3,
    };
  });

export const NATIONALITIES_WITH_POPULAR = COUNTRIES.map(item => {
  const isPopular = POPULAR_NATIONALITIES.includes(String(item.value));
  return {
    ...item,
    group: isPopular ? 'Popular' : 'All countries',
    groupOrder: isPopular ? 1 : 0,
  };
}) as Option[];

export const COUNTRIES_WITH_POPULAR = COUNTRIES.map(item => {
  const isPopular = POPULAR_COUNTRIES.includes(String(item.value));
  return {
    ...item,
    group: isPopular ? 'Popular' : 'All countries',
    groupOrder: isPopular ? 1 : 0,
  };
}) as Option[];
