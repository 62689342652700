import { getDocuments } from '@/api';
import {
  AbilityContext,
  Can,
  ClientBlockAnchor,
  Filter,
  Icon,
  InfiniteTablePage,
  Status,
  Switch,
  Table,
  ToggleGroup,
} from '@/components';
import {
  DOCUMENT_STATUSES,
  DOCUMENT_TYPES,
  DOCUMENT_UNKNOWN_FILTER_OPTIONS,
  DOCUMENT_TYPE_TEXT,
  ROUTE,
} from '@/constants';
import {
  useDispatch,
  useDownloadDocument,
  useInfiniteTable,
  useSelector,
} from '@/hooks';
import {
  selectConfigsState,
  selectDocumentReviewState,
  showDocumentRequestDrawer,
  showDocumentReviewDrawer,
} from '@/redux';
import {
  Document,
  DocumentStatus,
  OptionValue,
  Client,
  DocumentType,
  DocumentSection,
} from '@/types';
import {
  formatTime,
  getDataTestId,
  getNavigateObject,
  isViewSupportedDocument,
} from '@/utils';
import { Button, ListItemText, Stack, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { DocumentFilter } from './Documents.interface';
import { DocumentsFilterBar } from './DocumentsFilterBar';
import { DocumentBlockRow } from './Document.styled';
import { useNavigate } from 'react-router-dom';

export function DocumentsPage() {
  const {
    rows: documents,
    total,
    debouncedFilter,
    control,
    setValue,
    watch,
    onSortChange,
    nextPageTriggerRef,
    error,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useInfiniteTable<Document, DocumentFilter>({
    defaultValues: getUrlValue => ({
      inReviewOnly: getUrlValue('inReviewOnly', 'boolean') as boolean,
      statuses: getUrlValue('statuses', 'array') as DocumentStatus[],
      documentTypes: getUrlValue('documentTypes', 'array') as DocumentType[],
      unknownFilter: OptionValue.All,
      sortBy: (getUrlValue('sortBy', 'string') || '-updatedAt') as string,
    }),
    queryFn: getDocuments,
    getQueryFilter: filter => ({
      filter: {
        type: filter.documentTypes?.map(i => String(i)) || [],
        status: filter.inReviewOnly
          ? [DocumentStatus.InReview]
          : filter.statuses?.map(i => String(i)) || [],
      },
    }),
  });
  const downloadDocument = useDownloadDocument({
    disabledCache: true,
  });
  const ability = useContext(AbilityContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const canViewClient = ability.can('view', 'client');
  const statuses = watch('statuses');
  const inReviewOnly = watch('inReviewOnly');
  const sortBy = watch('sortBy');
  const { shouldRefreshData } = useSelector(selectDocumentReviewState);
  const { tenant } = useSelector(selectConfigsState);

  useEffect(() => {
    if (statuses?.length) {
      setValue('inReviewOnly', false);
    }
  }, [statuses, setValue]);

  useEffect(() => {
    if (inReviewOnly) {
      setValue('statuses', []);
    }
  }, [inReviewOnly, setValue]);

  useEffect(() => {
    if (shouldRefreshData) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshData]);

  const showDocumentRequestForm = () => dispatch(showDocumentRequestDrawer());

  const showDocumentReview = (document: Document) =>
    dispatch(
      showDocumentReviewDrawer({
        client: { id: document.clientId } as Client,
        document,
      }),
    );

  return (
    <InfiniteTablePage
      title="Documents"
      total={total}
      filterControl={control}
      error={error}
      fetchTriggerRef={nextPageTriggerRef}
      slots={{
        headingAction: (
          <Can do="request" on="document">
            <Button
              {...getDataTestId(`request-document-button`)}
              onClick={showDocumentRequestForm}
            >
              Request document
            </Button>
          </Can>
        ),
        headingSubAction: (
          <Switch
            control={control}
            name="inReviewOnly"
            label="Show only in review"
          />
        ),
        quickFilter: (
          <ToggleGroup
            options={DOCUMENT_UNKNOWN_FILTER_OPTIONS}
            name="unknownFilter"
            control={control}
          />
        ),
        filterBar: (
          <DocumentsFilterBar
            currentFilters={debouncedFilter}
            onSetFilters={setValue}
          />
        ),
      }}
    >
      <Table
        isLoading={isFetching || isFetchingNextPage}
        rows={documents || []}
        sortBy={sortBy}
        onSortChange={onSortChange}
        columns={[
          {
            header: 'Document',
            widthRatio: 17,
            cell: row => (
              <DocumentBlockRow
                onClick={
                  row.status !== DocumentStatus.Requested &&
                  isViewSupportedDocument(row)
                    ? () => showDocumentReview(row)
                    : undefined
                }
              >
                <ListItemText
                  primary={
                    <Typography variant="body2">
                      {row.type === DocumentSection.DocumentSign
                        ? row.formName
                        : DOCUMENT_TYPE_TEXT[row.docType || ''] ||
                          row.docType ||
                          'Document'}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="caption"
                      color={theme => theme.palette.grey[700]}
                    >
                      {DOCUMENT_TYPE_TEXT[row?.type || '']}
                    </Typography>
                  }
                />
              </DocumentBlockRow>
            ),
            filter: (
              <Filter
                name="documentTypes"
                options={DOCUMENT_TYPES}
                control={control}
              />
            ),
          },
          {
            header: 'Client',
            widthRatio: 20,
            cell: row => (
              <ClientBlockAnchor
                onClick={() => {
                  canViewClient &&
                    navigate(
                      getNavigateObject(
                        `/${tenant}/${ROUTE.CLIENTS.index}/${ROUTE.CLIENTS.CLIENT_DETAILS}`,
                        {
                          params: { id: row.clientId },
                        },
                      ),
                    );
                }}
                {...(canViewClient && {
                  href: row.clientId
                    ? `${ROUTE.CLIENTS.index}/${row.clientId}`
                    : undefined,
                })}
              >
                <ListItemText
                  primary={
                    <Typography variant="body2">{row.clientName}</Typography>
                  }
                  secondary={
                    <Typography
                      variant="caption"
                      color={theme => theme.palette.grey[700]}
                    >
                      ID: {row.clientId}
                    </Typography>
                  }
                />
              </ClientBlockAnchor>
            ),
          },
          {
            header: 'Status',
            widthRatio: 14,
            cell: row => <Status value={row.status} />,
            filter: (
              <Filter
                name="statuses"
                options={DOCUMENT_STATUSES}
                control={control}
              />
            ),
          },
          {
            dataKey: 'updatedAt',
            header: 'Last updated',
            widthRatio: 18,
            cell: row => formatTime(row.updatedAt),
            allowSort: true,
          },
          {
            dataKey: 'reviewDueDate',
            header: 'Due Date',
            widthRatio: 18,
            cell: row => formatTime(row.reviewDueDate),
          },
          {
            cell: row => (
              <Stack justifyContent="flex-end" alignItems="center">
                {row.status !== DocumentStatus.Requested && (
                  <Can do="review" on="document">
                    {isViewSupportedDocument(row) ? (
                      <Button
                        onClick={() => showDocumentReview(row)}
                        variant="outlined"
                        startIcon={<Icon name="check-square-offset" />}
                        {...getDataTestId(`${row.id}-review-button`)}
                      >
                        Review
                      </Button>
                    ) : (
                      <Button
                        onClick={() => downloadDocument(row)}
                        variant="outlined"
                        startIcon={<Icon name="download" />}
                        {...getDataTestId(`${row.id}-download-button`)}
                      >
                        Download
                      </Button>
                    )}
                  </Can>
                )}
              </Stack>
            ),
          },
        ]}
      />
    </InfiniteTablePage>
  );
}
