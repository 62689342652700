import { Box, styled } from '@mui/material';
import ReactPlayer from 'react-player';

export const MediaPlayer = styled(ReactPlayer)(() => ({
  objectFit: 'cover',
}));

export const VideoPlayerContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  borderRadius: 12,
  overflow: 'hidden',
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
