import { CountryCode3, DotNestedKeys } from './common';

export enum Language {
  English = 'en',
  Dutch = 'nl',
}

export type Translation = {
  location: {
    country: Record<CountryCode3 | 'VGB-shorten', string>;
    nationality: Record<CountryCode3, string>;
  };
};

export type TranslationKey = DotNestedKeys<Translation>;

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: 'ns1';
    resources: {
      [Language.English]: Translation;
      [Language.Dutch]: Translation;
    };
  }
}
