import { useEffect } from 'react';
import { useDispatch, useSelector } from './redux';
import { fetchClientDetails, selectClientDetails } from '@/redux';

export function useClientDetails(id?: string | number) {
  const client = useSelector(selectClientDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) {
      return undefined;
    }

    dispatch(fetchClientDetails(id));
  }, [dispatch, id]);

  return client;
}
