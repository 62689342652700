import {
  Document,
  DocumentSection,
  DocumentSectionQuestion,
  DocumentStatus,
  EmploymentStatus,
  RequiredDocumentGroup,
  RequiredDocumentSection,
} from '@/types';
import {
  DOCUMENT_SECTION_ORDER,
  DOCUMENT_SECTION_TEXT,
  DOCUMENT_TYPE_TEXT,
  EmploymentStatusText,
  ProfessionText,
  StatusText,
} from '@/constants';
import { useMemo } from 'react';
import {
  getBasicInfoStatus,
  getDocumentSigningSectionDetails,
  getProofOfEmploymentSectionDetails,
  getRequiredDocumentSectionDetails,
  getSignedDocuments,
  isNil,
} from '@/utils';
import { useSelector } from './redux';
import {
  selectClientAccountTypes,
  selectClientDetails,
  selectConfigsState,
  selectSigningDocuments,
} from '@/redux';

export function useClientCalculation() {
  const { requiredDocumentSections, employmentQuestionByStatus, tenant } =
    useSelector(selectConfigsState);
  const client = useSelector(selectClientDetails);
  const accountTypes = useSelector(selectClientAccountTypes);
  const formsToSign = useSelector(
    selectSigningDocuments({
      client: client!,
      clientAccountTypes: accountTypes,
      tenant,
    }),
  );

  const {
    idDocument,
    groupBySection = {},
    groups = [],
    forms = [],
  } = useMemo(() => {
    if (!client) {
      return {};
    }

    const requiredSectionDict: Record<string, RequiredDocumentSection> = (
      client.requiredDocumentSections || []
    ).reduce(
      (acc, section) => ({
        ...acc,
        [section.type]: section,
      }),
      {},
    );

    let idDocument: Document | undefined;
    const groupBySection: Partial<
      Record<
        DocumentSection,
        {
          section: DocumentSection;
          title: string;
          requiredDocGroups?: RequiredDocumentGroup[];
          items: Document[];
          generalStatus: DocumentStatus;
          description?: string;
          documentAnsweredQuestions?: DocumentSectionQuestion[];
        }
      >
    > = [
      ...requiredDocumentSections,
      ...client.requiredDocumentSections.map(i => i.type),
    ]
      .filter((type, index, array) => array.indexOf(type) === index)
      .reduce(
        (acc, section) => ({
          ...acc,
          [section]: {
            section,
            title:
              DOCUMENT_SECTION_TEXT[section]?.[tenant] ||
              DOCUMENT_SECTION_TEXT[section]?.default ||
              section,
            items: [],
            generalStatus: DocumentStatus.Pending,
          },
        }),
        {},
      );

    (client?.documents || []).forEach(item => {
      if (
        item.type === DocumentSection.Identity &&
        item.status !== DocumentStatus.Rejected
      ) {
        idDocument = item;
      }

      if (!groupBySection[item.type]) {
        groupBySection[item.type] = {
          section: item.type,
          title:
            DOCUMENT_SECTION_TEXT[item.type]?.[tenant] ||
            DOCUMENT_SECTION_TEXT[item.type]?.default ||
            item.type,
          items: [],
          generalStatus: DocumentStatus.Pending,
        };
      }

      if (groupBySection[item.type]) {
        groupBySection[item.type]?.items.push(item);
      }
    });

    Object.values(groupBySection).forEach(group => {
      let sectionDetails: Partial<RequiredDocumentSection>;

      switch (group.section) {
        case DocumentSection.DocumentSign:
          sectionDetails = getDocumentSigningSectionDetails(
            formsToSign,
            group.items,
          );
          break;
        case DocumentSection.ProofOfEmployment:
          sectionDetails = getProofOfEmploymentSectionDetails({
            requiredSection: requiredSectionDict[group.section],
            documentsInSection: group.items,
            client,
            tenant,
          });
          break;
        default:
          sectionDetails = getRequiredDocumentSectionDetails({
            requiredSection: requiredSectionDict[group.section],
            documentsInSection: group.items,
          });
      }
      group.requiredDocGroups = sectionDetails.groups;
      group.generalStatus = sectionDetails.status || DocumentStatus.Pending;

      switch (group.section) {
        case DocumentSection.ProofOfEmployment: {
          if (
            client?.employmentStatus === EmploymentStatus.SelfEmployed &&
            !isNil(client.selfEmployedRiskSectorFlag)
          ) {
            group.documentAnsweredQuestions = [
              {
                answer: client.selfEmployedRiskSectorFlag,
                question:
                  employmentQuestionByStatus[EmploymentStatus.SelfEmployed]
                    ?.question || '',
                description:
                  employmentQuestionByStatus[EmploymentStatus.SelfEmployed]
                    ?.description || '',
              },
            ];
          }

          const suffix = client.employmentDesc
            ? ProfessionText[client.employmentDesc] || client.employmentDesc
            : client.employmentStatus
              ? EmploymentStatusText[client.employmentStatus] ||
                client.employmentStatus
              : '';

          group.title = `${group.title}${suffix ? ` - ${suffix}` : ''}`;

          break;
        }

        case DocumentSection.DocumentSign: {
          if (!formsToSign.length) break;

          const signedDocs = getSignedDocuments(group.items, formsToSign);
          const remainingDocsToSign = Math.max(
            formsToSign.length - signedDocs.length,
            0,
          );

          group.items = group.items.map(doc => {
            const mappedForm = formsToSign.find(form =>
              form.docTypeWithAccountType
                ? form.docTypeWithAccountType === doc.docType
                : form.docType === doc.docType,
            );

            return {
              ...doc,
              formName: mappedForm?.name,
            };
          });

          group.description =
            StatusText[group.generalStatus] || group.generalStatus;

          if (remainingDocsToSign) {
            group.description += ` - ${remainingDocsToSign} document${remainingDocsToSign >= 2 ? 's' : ''} to sign`;
          }

          break;
        }
        case DocumentSection.IdentityBack:
          if (!group.items.length) {
            delete groupBySection[DocumentSection.IdentityBack];
          }
          break;
      }
    });

    return {
      idDocument,
      groupBySection,
      groups: Object.values(groupBySection)
        .map(group => ({
          ...group,
          items: group.items.sort((a, b) => {
            if (group.section === DocumentSection.DocumentSign) {
              return (
                DOCUMENT_TYPE_TEXT[a.docType as string] ||
                a.docType ||
                'Document'
              ).localeCompare(
                DOCUMENT_TYPE_TEXT[b.docType as string] ||
                  b.docType ||
                  'Document',
              );
            }

            const timeA = a.reviewDueDate
              ? new Date(a.reviewDueDate).getTime()
              : 0;
            const timeB = b.reviewDueDate
              ? new Date(b.reviewDueDate).getTime()
              : 0;

            return timeB - timeA;
          }),
        }))
        .sort(
          (a, b) =>
            DOCUMENT_SECTION_ORDER[a.section] -
            DOCUMENT_SECTION_ORDER[b.section],
        ),
      forms: formsToSign.map(form => ({
        ...form,
        isSigned: groupBySection[DocumentSection.DocumentSign]?.items.find(
          i =>
            i.adobeSignWidgetId === form.id &&
            i.status === DocumentStatus.Approved,
        ),
      })),
    };
  }, [
    client,
    requiredDocumentSections,
    formsToSign,
    tenant,
    employmentQuestionByStatus,
  ]);

  const basicInfoStatus = useMemo(() => getBasicInfoStatus(client), [client]);

  const onboardProgress = useMemo(() => {
    const statuses = [
      basicInfoStatus,
      ...(groups || [])
        .filter(
          i =>
            ![
              DocumentSection.Generated,
              DocumentSection.Internal,
              DocumentSection.BranchApproval,
            ].includes(i.section),
        )
        .map(i => i.generalStatus),
    ];
    return {
      finished: statuses.filter(status => status === DocumentStatus.Approved)
        .length,
      total: statuses.length,
    };
  }, [basicInfoStatus, groups]);

  const idDocuments =
    client?.documents?.filter(
      item =>
        ![DocumentStatus.Rejected].includes(item.status) &&
        [
          DocumentSection.Identity,
          DocumentSection.IdentityBack,
          DocumentSection.Selfie,
        ].includes(item.type),
    ) || [];

  const allIdDocsSubmitted =
    !!idDocuments.find(item => item.type === DocumentSection.Selfie) &&
    !!idDocuments.find(item => item.type === DocumentSection.Identity);

  return {
    isIDVerified:
      allIdDocsSubmitted &&
      idDocuments.every(item => item.status === DocumentStatus.Approved),
    idDocument,
    groupBySection,
    groups,
    forms,
    basicInfoStatus,
    onboardProgress,
  };
}
